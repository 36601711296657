import { decode } from "entities";
import { ApiSuccessStory, ApiSuccessStoryItem } from "../interface";
import { wpClient } from "./client";
import { getValuesFromTerms } from "../wp";

export class SuccessStoryApi {
  static async list(language: string): Promise<ApiSuccessStory[]> {
    const data: any[] = await wpClient.get(`wp/v2/success-story?acf_format=standard&per_page=100&_embed=wp:term&lang=${language}`);

    return data.reduce((acc, { title, acf, _embedded }) => {
      const categories = getValuesFromTerms(_embedded?.['wp:term'], 'category');
      const category = categories[0];
      const newGroup = acc.length === 0 || acc[acc.length - 1].name !== category;

      const successStory: ApiSuccessStoryItem = {
        ...acf,
        name: decode(title.rendered),
      };

      if (newGroup) {
        acc.push({
          items: [successStory],
          name: category,
        })
      } else {
        acc[acc.length - 1].items.push(successStory);
      }

      return acc;
    }, [] as ApiSuccessStory[]);
  }
}
