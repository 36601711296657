import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import { Container } from "./Container";
import { getResponsiveValue, useIsMobile } from "../utilities/hooks";
import { Image } from "./Image";
import { isEmptyText } from "../utilities/common";
import { FormatQuote } from "@mui/icons-material";
import { QuoteVariant } from "../utilities/interface";
import { useMemo } from "react";

interface VariantData {
  backgroundColor: string,
  showIcon: boolean,
}

const buildVariantData = (variant: QuoteVariant): VariantData => {
  switch (variant) {
    case 'secondary': {
      return {
        backgroundColor: 'rgba(0,0,0,0.85)',
        showIcon: false,
      }
    }

    default: {
     return {
      backgroundColor: '#B88E46',
      showIcon: true,
     }; 
    }
  }
}

interface QuoteProps {
  content: string,
  image: string,
  name: string;
  title: string;
  variant: QuoteVariant,
}

export const Quote = ({
  content,
  image,
  name,
  title,
  variant,
}: QuoteProps) => {
  const isMobile = useIsMobile();
  const { backgroundColor, showIcon } = useMemo(
    () => buildVariantData(variant),
    [variant],
  );

  return (
    <Container maxWidth="lg">
      <Stack
        direction={getResponsiveValue('column', 'row')}
        sx={{ minHeight: 734 }}
      >
        <Image
          src={image}
          sx={{
            objectFit: 'cover',
            width: getResponsiveValue('100%', '50%'),
          }}
        />
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Typography
            sx={{
              fontSize: 50,
              fontWeight: 600,
              lineHeight: '52px',
              my: getResponsiveValue(4, 0),
              textAlign: 'center',
            }}
          >
            {title}
          </Typography>
          <Stack
            spacing={3}
            sx={{
              backgroundColor,
              color: 'white',
              ml: getResponsiveValue(0, '-80px'),
              p: getResponsiveValue(2, '100px 100px 80px 80px'),
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 700,
                lineHeight: '23px',
                whiteSpace: 'pre-line',
              }}
            >
              {content}
            </Typography>
            {!isEmptyText(name) && (
              <Typography
                sx={{
                  alignSelf: 'flex-end',
                  fontSize: 18,
                  fontWeight: 500,
                  lineHeight: '23px',
                }}
              >
                {name}
              </Typography>
            )}
            {(showIcon && !isMobile) && (
              <>
                <Box
                  sx={{
                    fontSize: 200,
                    left: 0,
                    opacity: 0.4,
                    position: 'absolute',
                    top: 0,
                    transform: 'scaleX(-1) translateY(-45%)',
                  }}
                >
                  <FormatQuote fontSize="inherit" />
                </Box>
                <Box
                  sx={{
                    bottom: 0,
                    fontSize: 200,
                    opacity: 0.4,
                    position: 'absolute',
                    right: 0,
                    transform: 'translateY(55%)',
                  }}
                >
                  <FormatQuote fontSize="inherit" />
                </Box>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
