import { HorizontalRule } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { SuccessStory } from "./SuccessStory";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../constants/queryKeys";
import { SuccessStoryApi } from "../utilities/api/SuccessStoryApi";
import { ApiSuccessStoryItem } from "../utilities/interface";
import { Carousel } from "./Carousel";
import { Title } from "./Title";
import { isEmptyText } from "../utilities/common";

interface SuccessStoriesProps {
  divider?: boolean;
  subtitle?: string;
  title?: string;
}

export const SuccessStories = ({
  divider,
  subtitle,
  title,
}: SuccessStoriesProps) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { data } = useQuery({
    queryFn: () => SuccessStoryApi.list(language),
    queryKey: [QueryKeys.GET_SUCCESS_STORY, language],
  });

  const successStories = data?.reduce((acc, { items }) => {
    acc.push(...items);
    return acc;
  }, [] as ApiSuccessStoryItem[]);

  return (
    <Stack>
      {!isEmptyText(title) && (
        <Title divider={divider} subtitle={subtitle} title={title} />
      )}
      <Stack sx={{ alignItems: "center" }}>
        <Carousel
          activeIndicatorIconButtonProps={{
            style: {
              margin: 8,
              opacity: 1,
              transform: "scale(2)",
            },
          }}
          animation="slide"
          IndicatorIcon={<HorizontalRule fontSize="inherit" />}
          indicatorIconButtonProps={{
            style: {
              color: "#B18548",
              fontSize: 16,
              margin: 1,
              opacity: 0.5,
            },
          }}
          interval={8000}
          NextIcon={<></>}
          PrevIcon={<></>}
          sx={{ maxWidth: 1283, width: "100%" }}
        >
          {successStories?.map((successStory, index) => (
            <SuccessStory key={index} {...successStory} />
          ))}
        </Carousel>
      </Stack>
    </Stack>
  );
};
