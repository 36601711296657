import { Breakpoint, Stack, SxProps, Theme, Typography } from "@mui/material";
import { Title } from "./Title";
import { htmlParse } from "../utilities/htmlParse";
import { isEmptyText } from "../utilities/common";
import { TextVariant } from "../utilities/interface";
import { useMemo } from "react";
import { Wrapper } from "./Wrapper";
import { Image } from "./Image";
import { getResponsiveValue } from "../utilities/hooks";

interface VariantData {
  color?: string;
  fontSize?: number;
  maxWidth?: Breakpoint;
  spacing?: number;
  sx?: SxProps<Theme>;
  titleColor?: string;
}

const buildVariantData = (variant: TextVariant): VariantData => {
  switch (variant) {
    case "background": {
      return {
        color: "white",
        maxWidth: "lg",
        sx: {
          backgroundColor: "#B88E468C",
          color: "white",
          py: 14,
        },
      };
    }

    case "lotto": {
      return {
        fontSize: 26,
        maxWidth: "lg",
        titleColor: "#E30614",
      };
    }

    default: {
      return {
        fontSize: 26,
        maxWidth: "lg",
      };
    }
  }
};

interface TextProps {
  align?: "center" | "left" | "right";
  content: string;
  divider?: boolean;
  image?: string;
  subtitle?: string;
  title: string;
  variant?: TextVariant;
}

export const Text = ({
  align = "center",
  content,
  divider,
  image,
  subtitle,
  title,
  variant = "default",
}: TextProps) => {
  const { color, fontSize, maxWidth, spacing, titleColor, sx } = useMemo(
    () => buildVariantData(variant),
    [variant]
  );

  const titleAlign = useMemo(() => {
    if (align === "left") {
      return "flex-start";
    } else if (align === "right") {
      return "flex-end";
    }

    return "center";
  }, [align]);

  return (
    <Wrapper maxWidth={maxWidth} sx={sx}>
      {!isEmptyText(title) && (
        <Title
          align={titleAlign}
          color={titleColor ?? color}
          divider={divider}
          subtitle={subtitle}
          title={title}
        />
      )}
      <Stack direction={getResponsiveValue("column", "row")} spacing={2}>
        <Typography sx={{ color, fontSize }}>{htmlParse(content)}</Typography>
        <Image src={image} sx={{ objectFit: "contain" }} />
      </Stack>
    </Wrapper>
  );
};
