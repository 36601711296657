import { decode } from "entities";
import { ApiTestimonial } from "../interface";
import { wpClient } from "./client";

export class TestimonialApi {
  static async list(language: string): Promise<ApiTestimonial[]> {
    const data: any[] = await wpClient.get(`wp/v2/testimonial?acf_format=standard&per_page=100&_embed=wp:term&lang=${language}`);

    return data.map(({ id, title, acf }) => {
      return {
        ...acf,
        id,
        name: decode(title.rendered),
      };
    });
  }
}
