import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Filter } from "../components/Filter";
import { Map } from "../components/Map";
import { QueryKeys } from "../constants/queryKeys";
import { OpportunityApi } from "../utilities/api/OpportunityApi";
import { isEmptyText } from "../utilities/common";
import {
  getResponsiveValue,
  getResponsiveValues,
  useIsMobile,
} from "../utilities/hooks";
import { Categories, Opportunity } from "../utilities/interface";
import { LoadingPage } from "./LoadingPage";

interface MapPageProps {
  opportunity_list_slug: string;
  opportunity_slug: string;
}

export const MapPage = ({
  opportunity_list_slug,
  opportunity_slug,
}: MapPageProps) => {
  const [search, setSearch] = useState("");
  const [opportunity, setOpportunity] = useState<Opportunity | null>(null);
  const { category, id } = useParams<{ category: string; id: string }>();

  const isMobile = useIsMobile();

  const {
    i18n: { language },
  } = useTranslation();
  const { data } = useQuery({
    queryFn: () => OpportunityApi.list(language),
    queryKey: [QueryKeys.GET_OPPORTUNITY, language],
  });

  const opportunities = useMemo(
    () =>
      search === "" && category == null
        ? data
        : data?.filter((opportunity) => {
            const matchCategory =
              category == null ||
              category.replace(" ", "-").toLowerCase() ===
                opportunity.category.replace(" ", "-").toLowerCase();
            const matchSearch = search
              .split(/\s+/)
              .every((s) => new RegExp(s, "gi").test(opportunity.name));
            return matchCategory && matchSearch;
          }),
    [category, data, search]
  );

  const categories = useMemo(
    () =>
      opportunities?.reduce((acc, opportunity) => {
        if (acc[opportunity.category] == null) {
          acc[opportunity.category] = [];
        }

        acc[opportunity.category].push(opportunity);

        return acc;
      }, {} as Categories),
    [data, opportunities, category]
  );

  useEffect(() => {
    if (!isEmptyText(id)) {
      const newOpportunity = opportunities?.find(
        ({ slug }) => decodeURI(slug) === id
      );
      setOpportunity(newOpportunity ?? null);
    }
  }, [id, opportunities]);

  return opportunities == null ? (
    <LoadingPage />
  ) : (
    <Stack
      sx={{
        overflow: "hidden",
        pl: getResponsiveValue(0, 79),
        position: "relative",
      }}
    >
      {(!isMobile || opportunity == null) && (
        <Stack
          sx={getResponsiveValues(
            {},
            {
              bottom: 0,
              left: 0,
              overflow: "scroll",
              position: "absolute",
              top: 0,
            }
          )}
        >
          <Filter
            categories={categories ?? {}}
            expandAll={search !== "" || category != null}
            opportunity={opportunity}
            search={search}
            onClick={setOpportunity}
            onSearch={setSearch}
          />
        </Stack>
      )}
      <Map
        opportunities={opportunities ?? []}
        opportunity={opportunity}
        opportunitySlug={opportunity_slug}
        onClick={setOpportunity}
      />
    </Stack>
  );
};

export default MapPage;
