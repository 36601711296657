import { CarouselItem } from "./CarouselItem";
import { CarouselProps } from "./types";
import { Indicators } from "./Indicators";
import { sanitizeProps, useInterval } from "./util";
import {
  StyledButtonWrapper,
  StyledIconButton,
  StyledItemWrapper,
  StyledRoot,
} from "./Styled";
import { useEffect, useState } from "react";

export const Carousel = (props: CarouselProps) => {
  const [state, setState] = useState({
    active: 0,
    next: true,
    prevActive: 0,
  });

  /** Used to set carousel's height. It is being set by the CarouselItems */
  const [childrenHeight, setChildrenHeight] = useState<number>();
  const [paused, setPaused] = useState<boolean>(false);

  const sanitizedProps = sanitizeProps(props);

  // componentDidMount & onIndexChange
  useEffect(() => {
    const { index, changeOnFirstRender } = sanitizedProps;
    setNext(index, true, changeOnFirstRender);
  }, [sanitizedProps.index]);

  useInterval(() => {
    const { autoPlay } = sanitizedProps;

    if (autoPlay && !paused) {
      next(undefined);
    }
  }, sanitizedProps.interval);

  const next = (event: any) => {
    const { children, cycleNavigation } = sanitizedProps;

    let last = Array.isArray(children) ? children.length - 1 : 0;
    const nextActive =
      state.active + 1 > last
        ? cycleNavigation
          ? 0
          : state.active
        : state.active + 1;

    setNext(nextActive, true);

    if (event) event.stopPropagation();
  };

  const prev = (event: any) => {
    const { children, cycleNavigation } = sanitizedProps;

    let last = Array.isArray(children) ? children.length - 1 : 0;
    const nextActive =
      state.active - 1 < 0
        ? cycleNavigation
          ? last
          : state.active
        : state.active - 1;

    setNext(nextActive, false);

    if (event) event.stopPropagation();
  };

  const setNext = (
    index: number,
    isNext: boolean,
    runCallbacks: boolean = true
  ) => {
    const { onChange, children, strictIndexing } = sanitizedProps;

    if (Array.isArray(children)) {
      if (strictIndexing && index > children.length - 1)
        index = children.length - 1;
      if (strictIndexing && index < 0) index = 0;
    } else {
      index = 0;
    }

    if (runCallbacks) {
      if (isNext !== undefined)
        isNext
          ? sanitizedProps.next(index, state.active)
          : sanitizedProps.prev(index, state.active);

      onChange(index, state.active);
    }

    if (isNext === undefined) {
      isNext = index > state.active;
    }

    setState({
      active: index,
      next: isNext,
      prevActive: state.active,
    });
  };

  const {
    children,
    sx,
    className,

    height,

    stopAutoPlayOnHover,
    animation,
    duration,
    swipe,

    navButtonsAlwaysInvisible,
    navButtonsAlwaysVisible,
    cycleNavigation,
    fullHeightHover,
    navButtonsProps,
    navButtonsWrapperProps,
    NavButton,

    NextIcon,
    PrevIcon,

    indicators,
    indicatorContainerProps,
    indicatorIconButtonProps,
    activeIndicatorIconButtonProps,
    IndicatorIcon,
  } = sanitizedProps;

  const showButton = (next = true) => {
    if (cycleNavigation) return true;

    const last = Array.isArray(children) ? children.length - 1 : 0;

    if (next && state.active === last) return false;
    if (!next && state.active === 0) return false;

    return true;
  };

  return (
    <StyledRoot
      className={className}
      sx={sx}
      onBlur={() => {
        stopAutoPlayOnHover && setPaused(false);
      }}
      onFocus={() => {
        stopAutoPlayOnHover && setPaused(true);
      }}
      onMouseOut={() => {
        stopAutoPlayOnHover && setPaused(false);
      }}
      onMouseOver={() => {
        stopAutoPlayOnHover && setPaused(true);
      }}
      // style={{height: height}} // <-- number | undefined
    >
      <StyledItemWrapper style={{ height: height ? height : childrenHeight }}>
        {Array.isArray(children) ? (
          children.map((child, index) => {
            return (
              <CarouselItem
                animation={animation}
                child={child}
                duration={duration}
                height={height}
                index={index}
                key={`carousel-item${index}`}
                maxIndex={children.length - 1}
                next={next}
                prev={prev}
                setHeight={setChildrenHeight}
                state={state}
                swipe={swipe}
              />
            );
          })
        ) : (
          <CarouselItem
            animation={animation}
            child={children}
            duration={duration}
            height={height}
            index={0}
            key={`carousel-item0`}
            maxIndex={0}
            setHeight={setChildrenHeight}
            state={state}
          />
        )}
      </StyledItemWrapper>

      {!navButtonsAlwaysInvisible && showButton(true) && (
        <StyledButtonWrapper
          $fullHeightHover={fullHeightHover}
          $prev={false}
          $next
          {...navButtonsWrapperProps}
        >
          {NavButton !== undefined ? (
            NavButton({
              next: true,
              onClick: next,
              prev: false,
              ...navButtonsProps,
            })
          ) : (
            <StyledIconButton
              $alwaysVisible={navButtonsAlwaysVisible}
              $fullHeightHover={fullHeightHover}
              aria-label="Next"
              onClick={next}
              {...navButtonsProps}
            >
              {NextIcon}
            </StyledIconButton>
          )}
        </StyledButtonWrapper>
      )}

      {!navButtonsAlwaysInvisible && showButton(false) && (
        <StyledButtonWrapper
          $fullHeightHover={fullHeightHover}
          $next={false}
          $prev
          {...navButtonsWrapperProps}
        >
          {NavButton !== undefined ? (
            NavButton({
              next: false,
              onClick: prev,
              prev: true,
              ...navButtonsProps,
            })
          ) : (
            <StyledIconButton
              $alwaysVisible={navButtonsAlwaysVisible}
              $fullHeightHover={fullHeightHover}
              aria-label="Previous"
              onClick={prev}
              {...navButtonsProps}
            >
              {PrevIcon}
            </StyledIconButton>
          )}
        </StyledButtonWrapper>
      )}

      {indicators ? (
        <Indicators
          active={state.active}
          activeIndicatorIconButtonProps={activeIndicatorIconButtonProps}
          indicatorContainerProps={indicatorContainerProps}
          IndicatorIcon={IndicatorIcon}
          indicatorIconButtonProps={indicatorIconButtonProps}
          length={Array.isArray(children) ? children.length : 0}
          press={setNext}
        />
      ) : null}
    </StyledRoot>
  );
};
