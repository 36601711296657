import { Button, Stack, Typography } from "@mui/material";
import { getResponsiveValue } from "../utilities/hooks";
import { isEmptyText } from "../utilities/common";
import { useInView } from "react-intersection-observer";
import styled from "@emotion/styled";
import { Container } from "./Container";

const WhiteButton = styled(Button)({
  '&:active': {
    borderColor: 'white',
  },
  '&:hover': {
    borderColor: 'white',
  },
  borderColor: 'white',
  color: 'white',
  textTransform: 'none',
});

interface BackgroundProps {
  button_text: string,
  content: string,
  image: string,
  link: string,
  title: string,
}

export const Background = ({
  button_text,
  content,
  image,
  link,
  title,
}: BackgroundProps) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
  });

  return (
    <Stack
      ref={ref}
      sx={{
        background: inView ? `url(${image}) no-repeat center center` : undefined,
        backgroundSize: 'cover',
      }}
    >
      <Container
        sx={{
          backgroundColor: 'rgba(0,0,0,0.4)',
          pb: 14,
          pt: 30,
        }}
      >
        <Stack spacing={3} sx={{ alignItems: 'flex-start', maxWidth: 620 }}>
          {!isEmptyText(title) && (
            <Typography
              sx={{
                color: 'white',
                fontSize: getResponsiveValue(36, 100),
                fontWeight: 700,
                lineHeight: getResponsiveValue('37px', '104px'),
                textTransform: 'uppercase',
                whiteSpace: 'pre-line',
              }}
            >
              {title}
            </Typography>
          )}
          {!isEmptyText(content) && (
            <Typography
              sx={{
                color: 'white',
                fontSize: getResponsiveValue(14, 16),
                fontWeight: 500,
                lineHeight: getResponsiveValue('14px', '21px'),
              }}
            >
              {content}
            </Typography>
          )}
          {!(isEmptyText(button_text) && isEmptyText(link)) && (
            <WhiteButton
              href={link}
              size="large"
              variant="outlined"
            >
              {button_text}
            </WhiteButton>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
