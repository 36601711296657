import { common } from "./common";
import { contactForm } from "./contactForm";
import { zone } from "./zone";
import { download } from "./download";
import { filter } from "./filter";
import { languageSwitch } from "./languageSwitch";
import { header } from "./header";

export const arResources = {
  common,
  contactForm,
  download,
  filter,
  header,
  languageSwitch,
  zone,
};
