import { decode } from "entities";
import { Opportunity } from "../interface";
import { getValuesFromTerms } from "../wp";
import { wpClient } from "./client";

export class OpportunityApi {
  static async list(language: string): Promise<Opportunity[]> {
    const data: any[] = await wpClient.get(`wp/v2/opportunity?acf_format=standard&per_page=100&_embed=wp:term&lang=${language}`);

    return data.map(({ slug, id, title, acf, _embedded }) => {
      const {
        content,
        position,
        pdf,
        pdf_thumb,
        ...rest
      } = acf;

      const categories = getValuesFromTerms(_embedded['wp:term'], 'category');
      const cities = getValuesFromTerms(_embedded['wp:term'], 'city');

      return {
        ...rest,
        category: categories[0],
        city: cities[0],
        content: content?.trim().replace(/^\s*$/gm, '\n&nbsp;\n'),
        id,
        latitude: position?.lat,
        longitude: position?.lng,
        name: decode(title.rendered),
        pdf: !pdf ? undefined : {
          image: pdf_thumb,
          url: pdf,
        },
        slug,
      };
    });
  }
}
