import { TabItem } from "../utilities/interface";
import { Container } from "./Container";
import { isEmptyText } from "../utilities/common";
import { Title } from "./Title";
import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Image } from "./Image";
import { getResponsiveValue } from "../utilities/hooks";
import { htmlParse } from "../utilities/htmlParse";

export interface TabProps {
  items: TabItem[],
  title: string,
}

export const Tab = ({ items, title }: TabProps) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Container maxWidth="xl">
      {!isEmptyText(title) && (
        <Title align="flex-start" title={title} variant="dot" />
      )}
      <Stack direction="row" spacing={4} sx={{ mb: 7 }}>
        {items.map(({ title }, index) => (
          <Button
            color="success"
            key={index}
            size="large"
            sx={{
              borderRadius: 0,
              flex: 1,
              height: 77,
              textTransform: 'capitalize',
            }}
            variant={currentTab === index ? 'contained' : 'outlined'}
            onClick={() => setCurrentTab(index)}
          >
            {title}
          </Button>
        ))}
      </Stack>
      {items[currentTab] != null && (
        <Stack
          direction={getResponsiveValue('column', 'row')}
          spacing={10}
          sx={{ alignItems: 'center'}}
        >
          <Image
            src={items[currentTab].image}
            sx={{
              height: 360,
              objectFit: 'cover',
              width: getResponsiveValue('100%', 704),
            }}
          />
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 300,
              lineHeight: '23px',
            }}
          >
            {htmlParse(items[currentTab].content)}
          </Typography>
        </Stack>
      )}
    </Container>
  );
}
