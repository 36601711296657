import { Button, Stack, Typography } from "@mui/material";
import { Container } from "./Container";
import { useTranslation } from "react-i18next";
import { Image } from "./Image";
import { getResponsiveValue } from "../utilities/hooks";

interface DownloadProps {
  button_text?: string,
  content?: string,
  file: string,
  image: string,
}

export const Download = ({
  button_text,
  content,
  file,
  image
}: DownloadProps) => {
  const { t } = useTranslation('download');

  return (
    <Container
      maxWidth="md"
      sx={{
        backgroundColor: '#B88E468C',
        backgroundImage: `url(${process.env.PUBLIC_URL}/background-download.png)`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      <Stack
        alignItems={getResponsiveValue('center', 'flex-end')}
        direction={getResponsiveValue('column-reverse', 'row')}
        spacing={8}
        sx={{
          transform: 'translateY(-64px)',
        }}
      >
        <Stack alignItems={getResponsiveValue('center', 'flex-start')} spacing={8}>
          <Typography
            sx={{
              color: 'info.main',
              fontSize: 60,
              fontWeight: 700,
              lineHeight: '67px',
              textAlign: getResponsiveValue('center', 'left'),
            }}
          >
            {content ?? t('DOWNLOAD_FULL_PROFILE')}
          </Typography>

          <Button
            color="info"
            href={file}
            size="large"
            sx={{
              borderRadius: 0,
              textTransform: 'capitalize',
              width: 260,
            }}
            target="_blank"
            variant="contained"
          >
            {button_text ?? t('DOWNLOAD_NOW')}
          </Button>
        </Stack>
        <Image
          src={image}
          sx={{
            borderColor: '#C7C7C7',
            borderStyle: 'solid',
            borderWidth: 1,
            width: getResponsiveValue('100%', 369),
          }}
        />
      </Stack>
    </Container>
  );
}
