import { Box, ButtonBase, Stack } from "@mui/material";
import { arrayChunk, isEmptyText } from "../utilities/common";
import { ImageListItem } from "../utilities/interface";
import { Container } from "./Container";
import { Image } from "./Image";
import { Title } from "./Title";
import { useCallback, useMemo, useRef, useState } from "react";
import { Grid } from "./Grid";
import { Carousel } from "./Carousel";
import { ArrowBackIos, ArrowForwardIos } from "./Icons";
import { getResponsiveValue, useIsMobile } from "../utilities/hooks";

interface ImageListProps {
  divider: boolean;
  items: ImageListItem[];
  subtitle: string;
  title: string;
}

export const ImageList = ({
  divider,
  items,
  subtitle,
  title,
}: ImageListProps) => {
  const nextRef = useRef<Function | undefined>();
  const prevRef = useRef<Function | undefined>();

  const isMobile = useIsMobile();

  const [activeSlideIndex, setActiveSlideIndex] = useState<number | undefined>(
    0
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeItem, setActiveItem] = useState<ImageListItem>(
    items[activeIndex]
  );
  const slides = useMemo(() => arrayChunk(items, 12), [items]);

  const changeSlide = useCallback(
    (isNext: boolean, cb: Function) => {
      const activeSlide = slides[activeSlideIndex ?? 0];
      const newIndex = activeIndex + (isNext ? 1 : -1);

      if (activeSlide[newIndex] == null) {
        cb();
      } else {
        setActiveIndex(newIndex);
        setActiveItem(activeSlide[newIndex]);
      }
    },
    [activeSlideIndex, activeIndex, slides]
  );

  return (
    <Container maxWidth="lg">
      {!isEmptyText(title) && (
        <Title divider={divider} subtitle={subtitle} title={title} />
      )}

      <Carousel
        indicatorContainerProps={{
          style: {
            bottom: 12,
            position: "absolute",
            zIndex: 1,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            color: "white",
            fontSize: 16,
            margin: 1,
            opacity: 0.5,
          },
        }}
        interval={8000}
        NavButton={(props) => {
          if (props.next) {
            nextRef.current = () => changeSlide(true, props.onClick);
          } else if (props.prev) {
            prevRef.current = () => changeSlide(false, props.onClick);
          }

          return null;
        }}
        onChange={(now = 0, previous = 0) => {
          setActiveSlideIndex(now);

          const newIndex = now > previous ? 0 : slides[now].length - 1;
          setActiveIndex(newIndex);
          setActiveItem(slides[now][newIndex]);
        }}
      >
        {slides.map((slide) => (
          <Stack
            direction="row"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                flex: 3,
                mr: getResponsiveValue(0, 2),
                position: "relative",
              }}
            >
              <Image
                alt={activeItem.title}
                key={activeItem.image}
                src={activeItem.image}
                sx={{
                  boxSizing: "border-box",
                  objectFit: "contain",
                  width: "100%",
                }}
              />
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  bottom: 0,
                  justifyContent: "space-between",
                  left: 0,
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                <ButtonBase
                  sx={{
                    color: "white",
                    height: 80,
                    width: 80,
                  }}
                  onClick={() => prevRef.current?.()}
                >
                  <ArrowBackIos />
                </ButtonBase>
                <ButtonBase
                  sx={{
                    color: "white",
                    height: 80,
                    width: 80,
                  }}
                  onClick={() => nextRef.current?.()}
                >
                  <ArrowForwardIos />
                </ButtonBase>
              </Stack>
            </Box>
            {!isMobile && (
              <Grid desktop={3} mobile={12} spacing={2} sx={{ flex: 4 }}>
                {slide.map((item, index) => (
                  <Image
                    alt={item.title}
                    key={item.image}
                    src={item.image}
                    sx={{
                      boxSizing: "border-box",
                      cursor: "pointer",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    onClick={() => {
                      setActiveIndex(index);
                      setActiveItem(item);
                    }}
                  />
                ))}
              </Grid>
            )}
          </Stack>
        ))}
      </Carousel>
    </Container>
  );
};
