import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { App } from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// const container = document.getElementById('root');
// if (container != null) {
//   const root = ReactDOM.createRoot(container);

//   root.render(
//     <App />
//   );
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
