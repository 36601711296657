import { Container } from "./Container";
import { Grid } from "./Grid";
import { VideoListItem } from "../utilities/interface";
import { isEmptyText } from "../utilities/common";
import { Title } from "./Title";
import { Stack } from "@mui/material";
import ReactPlayer from "react-player";

interface VideoListProps {
  divider: boolean;
  items: VideoListItem[];
  subtitle: string;
  title: string;
}

export const VideoList = ({
  divider,
  items,
  subtitle,
  title,
}: VideoListProps) => {
  return (
    <Container maxWidth="lg">
      {!isEmptyText(title) && (
        <Title divider={divider} subtitle={subtitle} title={title} />
      )}

      <Grid desktop={6} mobile={12} spacing={4}>
        {items.map(({ title, youtube_video_id }) => (
          <Stack key={youtube_video_id}>
            <Title align="flex-start" color="black" title={title} divider />
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${youtube_video_id}`}
              width="100%"
            />
          </Stack>
        ))}
      </Grid>
    </Container>
  );
};
