import { ApiSettings, Language } from "../interface";
import { wpClient } from "./client";

export class OptionsApi {
  static async getSettings(): Promise<ApiSettings> {
    const data: any = await wpClient.get('options/all');

    data.languages = (data.languages as Language[]).reduce((acc, language) => {
      acc[language.key] = language;
      return acc;
    }, {} as Record<string, Language>);

    return data;
  }
}
