import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { ReactNode, useCallback } from "react";
import { useCookies } from "react-cookie";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ContactForm7Api } from "../utilities/api/ContactForm7";
import { useCondition, useSettings, useUuid } from "../utilities/hooks";
import { CompetitionItem } from "../utilities/interface";
import { Control } from "react-hook-form/dist/types";
import { isEmptyText } from "../utilities/common";
import { Image } from "./Image";

interface ContainerProps {
  children: ReactNode;
}

const Container = ({ children }: ContainerProps) => {
  return (
    <Stack
      sx={{
        alignItems: "stretch",
        alignSelf: "center",
        backgroundColor: "#f1f2f2",
        borderRadius: 3,
        justifyContent: "center",
        m: 6,
        px: 2,
        py: 4,
        width: 290,
      }}
    >
      {children}
    </Stack>
  );
};

interface CompetitionInputProps {
  control: Control;
  item: CompetitionItem;
  values: Record<string, any>;
}

const CompetitionInput = ({ control, item, values }: CompetitionInputProps) => {
  const id = useUuid();

  const showInput = useCondition(item.condition, values);

  if (!showInput) {
    return null;
  }

  return (
    <>
      {isEmptyText(item.name) ? (
        <Typography>{item.label}</Typography>
      ) : (
        <>
          {item.value === "" ? (
            <Controller
              control={control}
              defaultValue=""
              name={item.name}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  color="success"
                  error={fieldState.invalid}
                  label={item.label}
                  variant="outlined"
                />
              )}
              rules={{ required: item.required }}
            />
          ) : (
            <FormControl fullWidth>
              <InputLabel id={`select-${id}`}>{item.label}</InputLabel>
              <Controller
                control={control}
                defaultValue=""
                name={item.name}
                render={({ field, fieldState }) => (
                  <Select
                    {...field}
                    color="success"
                    error={fieldState.invalid}
                    label={item.label}
                    labelId={`select-${id}`}
                  >
                    {item.value.split("\r\n").map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                rules={{ required: item.required }}
              />
            </FormControl>
          )}
        </>
      )}
      <Image src={item.image} />
    </>
  );
};

interface CompetitionProps {
  end_date: string;
  end_message: string;
  id: string;
  items: CompetitionItem[];
  send_condition: string;
  start_date: string;
  start_message: string;
  success_message: string;
}

export const Competition = ({
  end_date,
  end_message,
  id,
  items,
  send_condition,
  start_date,
  start_message,
  success_message,
}: CompetitionProps) => {
  const { t, i18n } = useTranslation("contactForm");
  const [cookies] = useCookies(["token"]);

  const settings = useSettings();

  const { watch, control, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  const watchAllFields = watch();

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (evt: FieldValues) =>
      ContactForm7Api.feedback(cookies.token, id, evt as any),
    onSuccess: (data) => {
      // setToken(data.jwt);
      // navigate(`/${settings?.languages[i18n.language].home_slug}`);
    },
  });

  const handleSend = useCallback((evt: FieldValues) => mutate(evt), [mutate]);
  const showSend = useCondition(send_condition, watchAllFields);

  if (isSuccess) {
    return (
      <Container>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "25px",
            textAlign: "center",
          }}
        >
          {success_message}
        </Typography>
      </Container>
    );
  }

  // Y-m-d H:i:s
  if (dayjs().isBefore(dayjs(start_date))) {
    return (
      <Container>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "25px",
            textAlign: "center",
          }}
        >
          {start_message}
        </Typography>
      </Container>
    );
  }

  if (dayjs().isAfter(dayjs(end_date))) {
    return (
      <Container>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "25px",
            textAlign: "center",
          }}
        >
          {end_message}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Stack component="form" spacing={2} onSubmit={handleSubmit(handleSend)}>
        {items.map((item, index) => (
          <CompetitionInput
            control={control}
            item={item}
            key={index}
            values={watchAllFields}
          />
        ))}

        {showSend && (
          <Button
            color="secondary"
            disabled={!formState.isDirty || !formState.isValid || isPending}
            type="submit"
            variant="contained"
          >
            {t("SEND")}
          </Button>
        )}
      </Stack>
    </Container>
  );
};
