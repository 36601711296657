import { Typography } from "@mui/material";
import type { DOMNode } from "html-dom-parser";
import parse, {
  domToReact,
  Element,
  HTMLReactParserOptions,
} from "html-react-parser";

export const htmlParse = (html?: string) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        if (domNode.tagName === "px") {
          return (
            <Typography>
              {domToReact(domNode.children as DOMNode[], options)}
            </Typography>
          );
        }
      }
    },
  };

  return html == null ? null : parse(html, options);
};
