export const common = {
  DEVELOPMENT_ZONES: 'المناطق التنموية',
  KEY_INVESTMENT_SECTORS: 'قطاعات الاستثمار الرئيسية',
  KEY_INVESTMENT_SECTORS_DESCRIPTION: 'اكتشف الفرص الاستثمارية في القطاعات التالية',
  LEARN_MORE: 'المزيد',
  LIVING_IN_JORDAN: 'الحياة\nفي الأردن',
  LIVING_IN_JORDAN_DESCRIPTION: 'يجمع الاردن ما بين العراقة والحداثة، ويتميز بالتنوع الثقافي الغني وشعبه المضياف، في ظل توفر بيئة استثمارية جاهزة لاستقبال المستثمرين ورياديي الاعمال الذين يتطلعون إلى الاستفادة من الفرص الاستثمارية ذات العائد المجدي والمتاحة في مختلف الانشطة',
  MORE: 'المزيد',
  OPPORTUNITIES: 'الفرص',
  SECTORS: 'قطاعات الاستثمار',
  SUCCESS_STORIES: 'قصص نجاح',
  TESTIMONIALS: 'شهادات مستثمرين',
  VIEW_ON_MAP: 'الخريطة',
  WHERE_IMAGINATION_MEETS_OPPORTUNITY: 'حيث يتحول\nالإبداع لفرصة',
  WHY_JORDAN: 'لماذا الاستثمار في الأردن',
};
