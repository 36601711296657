import {PageListItem} from "../utilities/interface";
import {isEmptyText} from "../utilities/common";
import {Title} from "./Title";
import {Box, Stack} from "@mui/material";
import {Grid} from "./Grid";
import {Card} from "./Card";

interface PageListProps {
  divider: boolean,
  items: PageListItem[],
  subtitle: string,
  title: string,
}

export const PageList = ({
  divider,
  items,
  subtitle,
  title,
}: PageListProps) => {
  return (
    <Stack>
      {!isEmptyText(title) && (
        <Title
          divider={divider}
          subtitle={subtitle}
          title={title}
        />
      )}
      <Box sx={{ pb: 14, px: 2 }}>
        <Grid
          columnSpacing={2}
          desktop={4}
          mobile={12}
          rowSpacing={14}
        >
          {items.map((item, index) => (
            <Card
              category={item.category}
              description={item.description}
              feature={item.feature}
              image={item.image}
              key={index}
              link={item.link}
              title={item.title}
            />
          ))}
        </Grid>
      </Box>
    </Stack>
  );
}
