import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { getResponsiveValue } from "../../utilities/hooks";
import { Image } from "../Image";
import { isEmptyText } from "../../utilities/common";

interface StarBoxProps {
  color?: 'primary' | 'secondary',
  image: string,
  subtitle: string,
  sup?: string,
  title: string,
}

export const StarBox = ({
  color = 'primary',
  image,
  sup,
  subtitle,
  title,
}: StarBoxProps) => {
  return (
    <Stack alignItems="center" flex={1} spacing={1}>
      <Image
        src={image}
        sx={{
          height: getResponsiveValue(66, 106),
          objectFit: 'contain',
          width: getResponsiveValue(66, 106),
        }}
      />
      {!isEmptyText(title) && (
        <Typography
          sx={{
            color: color === 'primary' ? 'secondary.main' : undefined,
            fontSize: getResponsiveValue(39, 60),
            fontWeight: '700',
            letterSpacing: -3,
            textAlign: 'center',
          }}
        >
          {title}
          {!isEmptyText(sup) && (
            <Box
              component="sup"
              sx={{
                verticalAlign: 'top',
              }}
            >
              {sup}
            </Box>
          )}
        </Typography>
      )}
      {!isEmptyText(subtitle) && (
        <Typography
          sx={{
            color: color === 'secondary' ? 'secondary.main' : undefined,
            fontSize: getResponsiveValue(11, 18),
            fontWeight: '500',
            lineHeight: getResponsiveValue('14px', '23px'),
            textAlign: 'center',
            whiteSpace: 'pre-line',
            width: getResponsiveValue(200, 300),
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
}
