import {
  Breakpoint,
  Container as MuiContainer,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { forwardRef, ReactNode } from "react";

interface ContainerProps {
  children: ReactNode;
  maxWidth?: Breakpoint;
  sx?: SxProps<Theme>;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, maxWidth = "lg", sx }, ref) => {
    return children == null ? null : (
      <Stack ref={ref} sx={sx}>
        <MuiContainer maxWidth={maxWidth}>{children}</MuiContainer>
      </Stack>
    );
  }
);
