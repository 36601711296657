import { Button, Snackbar, Stack, TextField } from "@mui/material";
import { Title } from "./Title";
import { Container } from "./Container";
import { getResponsiveValue, useIsMobile } from "../utilities/hooks";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { isEmptyText } from "../utilities/common";
import { wpClient } from "../utilities/api/client";

interface ContactFormProps {
  divider?: boolean;
  subtitle?: string;
  title?: string;
}

export const ContactForm = ({ divider, subtitle, title }: ContactFormProps) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("contactForm");
  const [open, setOpen] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSend = useCallback(
    async (evt: FieldValues) => {
      if (!executeRecaptcha) {
        return;
      }

      try {
        const token = await executeRecaptcha("send");
        if (!!token) {
          await wpClient.put("contact/v1/send", {
            contact_email: evt.email,
            contact_message: evt.message,
            contact_name: evt.name,
            contact_subject: evt.subject,
          });

          // await send(SERVICE_ID, TEMPLATE_ID, evt, PUBLIC_KEY);
          setOpen(true);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [executeRecaptcha]
  );

  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/background-contact-form.svg)`,
          backgroundPosition: getResponsiveValue("left bottom", "-100% bottom"),
          backgroundRepeat: "no-repeat",
          backgroundSize: getResponsiveValue("cover", "70%"),
          py: 13,
        }}
      >
        <Title
          divider={divider}
          subtitle={subtitle}
          title={isEmptyText(title) ? t("GET_IN_TOUCH_WITH_US") : title}
        />
        <Stack
          component="form"
          spacing={4}
          sx={{ alignItems: "center" }}
          onSubmit={handleSubmit(handleSend)}
        >
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={4}
            width="100%"
          >
            <Controller
              control={control}
              defaultValue=""
              name="name"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  label={t("ENTER_YOUR_NAME")}
                  sx={{ flex: 1 }}
                  variant="outlined"
                />
              )}
              rules={{ required: true }}
            />
            <Controller
              control={control}
              defaultValue=""
              name="email"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  label={t("EMAIL")}
                  sx={{ flex: 1 }}
                  variant="outlined"
                />
              )}
              rules={{
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                required: true,
              }}
            />
            <Controller
              control={control}
              defaultValue=""
              name="subject"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  label={t("SUBJECT")}
                  sx={{ flex: 1 }}
                  variant="outlined"
                />
              )}
              rules={{ required: true }}
            />
          </Stack>
          <Controller
            control={control}
            defaultValue=""
            name="message"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={fieldState.invalid}
                label={t("YOUR_MESSAGE_HERE")}
                minRows={4}
                sx={{ width: "100%" }}
                variant="outlined"
                multiline
              />
            )}
            rules={{ required: true }}
          />
          <Button
            disabled={!formState.isDirty || !formState.isValid}
            sx={{ px: 8 }}
            type="submit"
            variant="contained"
          >
            {t("SEND")}
          </Button>
        </Stack>
      </Container>
      <Snackbar
        message={t("THANK_YOU_MESSAGE")}
        open={open}
        sx={{ whiteSpace: "pre-line" }}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
