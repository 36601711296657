import { decode } from "entities";
import { ApiCustomPage } from "../interface";
import { wpClient } from "./client";

export class CustomPageApi {
  static async getPage(
    slug: string,
    language: string
  ): Promise<ApiCustomPage | null> {
    const params = {
      // _embed: 'wp:term',
      acf_format: "standard",
      lang: language,
      per_page: 1,
      slug,
    };

    const data: any[] = await wpClient.get("wp/v2/custom-pages", { params });
    if (data?.[0] == null) {
      return null;
    }

    const { title, acf } = data[0];

    return {
      backgroundColor: acf.background_color,
      backgroundColorMobile: acf.background_color_mobile,
      backgroundImage: acf.background_image,
      backgroundImageMobile: acf.background_image_mobile,
      cols:
        acf.column?.map(({ acf_fc_layout, ...rest }: any) => ({
          ...rest,
          type: acf_fc_layout,
        })) ?? [],
      description: acf.description,
      keywords: acf.description,
      title: decode(title.rendered),
    };
  }

  static async getId(
    slug: string,
    language: string
  ): Promise<number | undefined> {
    const params = {
      _fields: "id",
      lang: language,
      per_page: 1,
      slug,
    };

    const data: any = await wpClient.get("wp/v2/custom-pages", { params });

    return data?.[0]?.["id"];
  }
}
