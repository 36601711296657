import { FiberManualRecord } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";

export const StyledRoot = styled("div")({
  overflow: "hidden",
  position: "relative",
});

export const StyledItem = styled("div")({
  height: "100%",
  position: "absolute",
  width: "100%",
  //    flexGrow: 1
});

export const StyledItemWrapper = styled("div")({
  height: "100%",
  position: "relative",
  width: "100%",
});

export const StyledIndicators = styled("div")({
  marginTop: "10px",
  textAlign: "center",
  width: "100%",
});

export const StyledFiberManualRecordIcon = styled(FiberManualRecord)({
  fontSize: "15px",
});

export const StyledIndicatorIconButton = styled(IconButton, {
  shouldForwardProp: (propName: string) => !propName.startsWith("$"),
})<{ $active: boolean }>(({ $active }) => ({
  "&:active": {
    color: $active ? "#494949" : "#1f1f1f",
  },
  "&:hover": {
    color: $active ? "#494949" : "#1f1f1f",
  },
  color: $active ? "#494949" : "#afafaf",
  cursor: "pointer",
  padding: 0,
  transition: "200ms",
}));

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (propName: string) => !propName.startsWith("$"),
})<{ $alwaysVisible: boolean; $fullHeightHover: boolean }>(
  ({ $alwaysVisible }) => ({
    "&:hover": {
      opacity: "0.6 !important",
    },
    backgroundColor: "#494949",
    color: "white",
    cursor: "pointer",
    fontSize: "30px",
    margin: "0 10px",
    opacity: $alwaysVisible ? "1" : "0",
    position: "relative",
    top: "calc(50% - 20px) !important",
    transition: "200ms",
  })
);

export const StyledButtonWrapper = styled("div", {
  shouldForwardProp: (propName: string) => !propName.startsWith("$"),
})<{ $next: boolean; $prev: boolean; $fullHeightHover: boolean }>(
  ({ $next, $prev, $fullHeightHover }) => ({
    "&:hover": {
      "& button": {
        backgroundColor: "black",
        filter: "brightness(120%)",
        opacity: "0.4",
      },
    },
    backgroundColor: "transparent",
    height: "100px",
    position: "absolute",
    top: "calc(50% - 70px)",
    zIndex: 1,
    ...($fullHeightHover
      ? {
          height: "100%", // This is 100% - indicator height - indicator margin
          top: "0",
        }
      : undefined),
    ...($next ? { right: 0 } : undefined),
    ...($prev ? { left: 0 } : undefined),
  })
);
