import { useState } from "react";
import { Grid } from "../../utilities/interface";
import { getResponsiveValue, useIsMobile } from "../../utilities/hooks";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { Button, Stack, Typography, styled } from "@mui/material";
import { isEmptyText } from "../../utilities/common";

const WhiteButton = styled(Button)({
  '&:active': {
    borderColor: 'white',
  },
  '&:hover': {
    borderColor: 'white',
  },
  borderColor: 'white',
  color: 'white',
  textTransform: 'none',
});

export type Grid2Props = Grid;

export const Grid2 = ({
  category,
  content,
  image,
  link,
  title,
}: Grid2Props) => {
  const [hover, setHover] = useState(false);
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
  });

  return (
    <Stack
      ref={ref}
      sx={{
        backgroundImage: inView ? `url(${image})` : undefined,
        backgroundPositionX: hover ? 'right' : 'left',
        backgroundSize: 'cover',
        flex: getResponsiveValue(undefined, 1),
        height: getResponsiveValue(338, 612),
        justifyContent: getResponsiveValue('space-between', 'flex-end'),
        overflow: 'hidden',
        p: getResponsiveValue(0, 4),
        transition: 'background-position-x 0.25s',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {isMobile ? (
        <>
          {!isEmptyText(category) && (
            <Typography
              sx={{
                alignSelf: 'flex-start',
                backgroundColor: 'rgb(1,65,65,0.8)',
                color: 'info.main',
                fontSize: 14,
                fontWeight: 700,
                px: 2,
                py: 1,
              }}
            >
              {category}
            </Typography>
          )}
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              p: 2,
            }}
          >
            <Typography
              sx={{
                color: 'info.main',
                fontSize: 24,
                fontWeight: 700,
                lineHeight: '29px',
              }}
            >
              {title}
            </Typography>
            {!isEmptyText(link) && (
              <WhiteButton
                color="success"
                href={link}
                size="medium"
                sx={{
                  whiteSpace: 'nowrap',
                }}
                variant="outlined"
              >
                {t('LEARN_MORE')}
              </WhiteButton>
            )}
          </Stack>
        </>
      ) : (
        <>
          {hover ? (
            <Stack
              spacing={2}
              sx={{
                alignItems: 'flex-start',
                backgroundColor: 'rgba(0,0,0,0.85)',
                justifyContent: 'flex-end',
                overflow: 'hidden',
                p: 4,
              }}
            >
              <Typography
                sx={{
                  color: 'info.main',
                  fontSize: 32,
                  fontWeight: 700,
                  lineHeight: '39px',
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  color: 'info.main',
                  fontSize: 16,
                  fontWeight: 300,
                  lineHeight: '22px',
                  overflow: 'hidden',
                }}
              >
                {content}
              </Typography>
              {link !== '' && (
                <Button
                  color="success"
                  href={link}
                  size="large"
                  sx={{ textTransform: 'capitalize' }}
                  variant="outlined"
                >
                  {t('LEARN_MORE')}
                </Button>
              )}
            </Stack>
          ) : (
            <Typography sx={{ color: 'info.main', fontSize: 36, fontWeight: 700 }}>
              {category}
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
}
