import { Box, Stack, Typography } from "@mui/material";
import { isEmptyText } from "../../utilities/common";
import { useInView } from "react-intersection-observer";

interface CircleProps {
  color?: 'primary' | 'secondary',
  image: string,
  subtitle: string,
  sup?: string,
  title: string,
}

export const Circle = ({
  color,
  image,
  subtitle,
  sup,
  title,
}: CircleProps) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
  });

  return (
    <Stack alignItems="center" flex={1} ref={ref} spacing={1}>
      <Stack
        sx={{
          alignItems: 'center',
          backgroundColor: color === 'primary' ? '#272727' : '#B88E46',
          borderRadius: '50%',
          boxSizing: 'border-box',
          height: 280,
          justifyContent: isEmptyText(title) ? 'center' : 'flex-start',
          p: 6,
          position: 'relative',
          width: 280,
        }}
      >
        <Box
          sx={{
            backgroundImage: inView ? `url(${image})` : undefined,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            borderColor: color === 'primary' ? '#B88E46' : '#272727',
            borderRadius: '50%',
            borderStyle: 'solid',
            borderWidth: 1,
            height: 255,
            left: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 255,
          }}
        />
        {!isEmptyText(title) && (
          <Typography
            sx={{
              color: '#B88E46',
              fontSize: 69,
              fontWeight: 700,
              letterSpacing: -3,
              lineHeight: '67px',
              mb: 1,
              textAlign: 'center',
            }}
          >
            {title}
            {!isEmptyText(sup) && (
              <Box
                component="sup"
                sx={{
                  verticalAlign: 'top',
                }}
              >
                {sup}
              </Box>
            )}
          </Typography>
        )}
        {!isEmptyText(subtitle) && (
          <Typography
            sx={{
              color: 'white',
              fontSize: 17,
              fontWeight: 300,
              lineHeight: '23px',
              textAlign: 'center',
            }}
          >
            {subtitle}
          </Typography>
      )}
      </Stack>
    </Stack>
  );
}
