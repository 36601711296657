import { ButtonBase, Typography } from "@mui/material";
import { PdfListItem } from "../utilities/interface";
import { isEmptyText } from "../utilities/common";
import { Title } from "./Title";
import { Container } from "./Container";
import { Grid } from "./Grid";
import { Image } from "./Image";

interface PdfListProps {
  divider: boolean,
  items: PdfListItem[],
  subtitle: string,
  title: string,
}

export const PdfList = ({
  divider,
  items,
  subtitle,
  title,
}: PdfListProps) => {
  return (
    <Container maxWidth="lg">
      {!isEmptyText(title) && (
        <Title
          divider={divider}
          subtitle={subtitle}
          title={title}
        />
      )}
      <Grid
        columnSpacing={4}
        desktop={3}
        mobile={12}
        rowSpacing={10}
        sx={{ justifyContent: 'center' }}
      >
        {items.map((item) => (
          <ButtonBase
            href={item.pdf_file}
            sx={{
              alignItems: 'center',
              borderColor: '#B88E46',
              borderStyle: 'solid',
              borderWidth: 1,
              boxShadow: '0px 3px 6px #00000036',
              boxSizing: 'border-box',
              flexDirection: 'column',
              height: 310,
              justifyContent: 'space-between',
              p: 4,
              width: '100%',
            }}
            target="_blank"
          >
            <Image
              src={item.image}
              sx={{
                height: 80,
                my: 4,
                objectFit: 'cover',
                width: 70,
              }}
            />
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 600,
                lineHeight: '25px',
                textAlign: 'center',
              }}
            >
              {item.title}
            </Typography>
          </ButtonBase>
        ))}
      </Grid>
    </Container>
  );
}
