import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { arResources } from "./ar";

export const supportedLngs = ["ar"];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: "ar",
    fallbackNS: "common",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    resources: {
      ar: arResources,
    },
    supportedLngs,
  });

export default i18n;
