import { Grid2Props, Unstable_Grid2 as MuiGrid } from "@mui/material";
import { Children, ReactNode } from "react";
import { getGridResponsiveSize } from "../utilities/hooks";

interface GridProps extends Pick<Grid2Props, 'rowSpacing' | 'columnSpacing' | 'spacing' | 'sx'> {
  children: ReactNode,
  desktop: number,
  mobile: number,
}

export const Grid = ({
  children,
  desktop,
  mobile,
  ...props
}: GridProps) => {
  return (
    <MuiGrid container {...props}>
      {Children.map(children, (child) => (
        <MuiGrid {...getGridResponsiveSize(mobile, desktop)}>
          {child}
        </MuiGrid>
      ))}
    </MuiGrid>
  );
}
