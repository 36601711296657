import { Box, Stack, Typography } from "@mui/material";
import { getResponsiveValue, useIsMobile } from "../utilities/hooks";
import { ApiSuccessStoryItem } from "../utilities/interface";
import { Image } from "./Image";
import { isEmptyText } from "../utilities/common";

interface SuccessStoryProps extends ApiSuccessStoryItem {
  direction?: 'left' | 'right',
};

export const SuccessStory = ({
  description,
  direction = 'left',
  image,
  logo,
  name,
}: SuccessStoryProps) => {
  const isMobile = useIsMobile();

  const Img = () => (
    <Stack sx={{ flex: 1, justifyContent: 'center' }}>
      <Image
        src={image}
        sx={{
          borderRadius: 2,
          height: 342,
          objectFit: 'cover',
          width: '100%',
        }}
      />
    </Stack>
  );

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      spacing={isMobile ? 8 : undefined}
    >
      {(isMobile || direction === 'left') && <Img />}
      <Stack
        spacing={2}
        sx={{
          alignItems: getResponsiveValue('center', 'flex-start'),
          flex: 1,
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            pl: direction === 'left' ? 6 : 3,
            pr: direction === 'left' ? 3 : 6,
          }}
        >
          {isEmptyText(logo) ? (
            <Typography
              color="primary"
              sx={{
                fontSize: 22,
                fontWeight: 500,
                mb: 1,
              }}
            >
              {name}
            </Typography>
          ) : (
            <Image src={logo} sx={{ mb: 1 }} />
          )}
          <Typography
            color="secondary.main"
            sx={{
              fontSize: 16,
              fontWeight: 300,
              lineHeight: '25px',
              textAlign: getResponsiveValue('center', 'left'),
            }}
          >
            {description}
          </Typography>
          {/* <Button
            color="success"
            size="large"
            sx={{ textTransform: 'capitalize' }}
            variant="outlined"
          >
            Learn More
          </Button> */}
        </Box>
      </Stack>
      {(!isMobile && direction === 'right') && <Img />}
    </Stack>
  );
}
