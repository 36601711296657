import { Grid } from "../../utilities/interface";
import { getResponsiveValue } from "../../utilities/hooks";
import { Divider, Stack, Typography } from "@mui/material";
import { isEmptyText } from "../../utilities/common";

export type Grid3Props = Grid;

export const Grid3 = ({
  category,
  content,
  image,
  link,
  title,
}: Grid3Props) => {
  return (
    <Stack
      sx={{
        flex: getResponsiveValue(undefined, 1),
        p: getResponsiveValue(0, 6),
      }}
    >
      <Typography
        sx={{
          fontSize: 26,
          fontWeight: 500,
          lineHeight: '40px',
        }}
      >
        {title}
      </Typography>
      <Divider
        sx={{
          bgcolor: 'secondary.main',
          mb: 6,
          mt: 2,
        }}
        flexItem
      />
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 300,
          lineHeight: '23px',
          whiteSpace: 'pre-line',
        }}
      >
        {content}
      </Typography>
    </Stack>
  );
}
