import { KeyboardArrowDown, Search } from "@mui/icons-material";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Slider,
  styled,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { Categories, Opportunity } from "../utilities/interface";
import { useTranslation } from "react-i18next";
import { getResponsiveValue } from "../utilities/hooks";

const WhiteTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
  },
  '& input': {
    color: 'white',
  },
  '& label': {
    color: 'white',
  },
});

const Accordion = styled(MuiAccordion)({
  '&:before': {
    display: 'none',
  },
  border: 'none',
});

interface FilterProps {
  categories: Categories,
  expandAll: boolean,
  onClick(opportunity: Opportunity | null): void;
  onSearch(search: string): void,
  opportunity: Opportunity | null,
  search: string,
}

export const Filter = ({
  categories,
  expandAll,
  onClick,
  onSearch,
  opportunity,
  search,
}: FilterProps) => {
  const { t } = useTranslation('filter');
  const [advanced, setAdvanced] = useState(false);
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [mainSector, setMainSector] = useState('');
  const [zone, setZone] = useState('');
  const [project, setProject] = useState('');
  const [opportunityType, setOpportunityType] = useState('');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Stack
      direction="row"
      spacing={13}
      sx={{
        bgcolor: 'primary.main',
        flex: 1,
        p: getResponsiveValue(3, 8),
      }}
    >
      <Stack spacing={3} sx={{ width: getResponsiveValue('auto', 505) }}>
        {/* <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Button
            color="info"
            sx={{ fontSize: 14, fontWeight: 300, textTransform: 'capitalize' }}
            variant="text"
            onClick={() => setAdvanced(!advanced)}
          >
            Advanced Search
          </Button>
        </Stack> */}
        <WhiteTextField
          color="info"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="info">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={t('SEARCH_OPPORTUNITIES')}
          value={search}
          variant="outlined"
          onChange={(event) => onSearch(event.target.value)}
        />
        <Stack divider={<Divider sx={{ bgcolor: 'info.main' }} />}>
          {Object.keys(categories).map((category) => (
            <Accordion
              elevation={0}
              expanded={expandAll || expanded === category}
              key={category}
              square
              onChange={handleChange(category)}
            >
              <AccordionSummary
                expandIcon={expandAll ? undefined : <KeyboardArrowDown color="info" />}
                sx={{ m: 0, p: 0 }}
              >
                <Typography color="white" sx={{ fontSize: 26, fontWeight: 500 }}>
                  {category}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 3 }}>
                <Stack>
                  {categories[category].map((_opportunity) => (
                    <Button
                      color="info"
                      key={_opportunity.id}
                      size="small"
                      sx={{
                        fontSize: 22,
                        fontWeight: opportunity?.slug === _opportunity.slug ? 700 : 300,
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        textTransform: 'capitalize',
                      }}
                      variant="text"
                      onClick={() => {
                        // setCategory(category === place.id ? -1 : place.id);
                        onClick(opportunity?.slug === _opportunity.slug ? null : _opportunity);
                      }}
                    >
                      {_opportunity.name}
                    </Button>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      </Stack>
      {advanced && (
        <Stack spacing={4} sx={{ width: 664 }}>
          <Stack>
            <Typography sx={{ color: 'white', fontSize: 11, fontWeight: 300 }}>
              Sort by Investment Value
            </Typography>
            <Typography sx={{ color: 'white', fontSize: 23, fontWeight: 700 }}>
              Investment Value
            </Typography>
            <Slider color="secondary" defaultValue={30} />
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Typography sx={{ color: 'white', fontSize: 23, fontWeight: 700 }}>20K</Typography>
              <Typography sx={{ color: 'white', fontSize: 23, fontWeight: 700 }}>2M</Typography>
            </Stack>
          </Stack>

          <Stack>
            <Typography sx={{ color: 'white', fontSize: 11, fontWeight: 300 }}>
              Sort by Investment Rate of Return
            </Typography>
            <Typography sx={{ color: 'white', fontSize: 23, fontWeight: 700 }}>
              Investment Rate of Return
            </Typography>
            <Slider color="secondary" defaultValue={30} />
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Typography sx={{ color: 'white', fontSize: 23, fontWeight: 700 }}>3%</Typography>
              <Typography sx={{ color: 'white', fontSize: 23, fontWeight: 700 }}>20%</Typography>
            </Stack>
          </Stack>

          <Stack direction="row" spacing={10}>
            <Stack flex={1} spacing={1}>
              <Typography sx={{ color: 'white', fontSize: 16, fontWeight: 300 }}>
                Main Sectors
              </Typography>
              <Select
                color="info"
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'white',
                  },
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  color: 'white',
                }}
                value={mainSector}
                variant="outlined"
                displayEmpty
                onChange={(event) => setMainSector(event.target.value)}
              >
                <MenuItem value="">
                  Select one value Only
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>

              {/* <WhiteTextField
                color="info"
                label="Select one value Only"
                variant="outlined"
                sx={{ borderColor: 'white' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color="info">
                        <Info />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Stack>

            <Stack flex={1} spacing={1}>
              <Typography sx={{ color: 'white', fontSize: 16, fontWeight: 300 }}>
                Zones
              </Typography>
              <Select
                color="info"
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'white',
                  },
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  color: 'white',
                }}
                value={zone}
                variant="outlined"
                displayEmpty
                onChange={(event) => setZone(event.target.value)}
              >
                <MenuItem value="">
                  Select one value Only
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              {/* <WhiteTextField
                color="info"
                label="Select one value Only"
                variant="outlined"
                sx={{ borderColor: 'white' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color="info">
                        <Info />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Stack>
          </Stack>

          <Stack direction="row" spacing={10}>
            <Stack flex={1} spacing={1}>
              <Typography sx={{ color: 'white', fontSize: 16, fontWeight: 300 }}>
                Project
              </Typography>
              <Select
                color="info"
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'white',
                  },
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  color: 'white',
                }}
                value={project}
                variant="outlined"
                displayEmpty
                onChange={(event) => setProject(event.target.value)}
              >
                <MenuItem value="">
                  Select one value Only
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              {/* <WhiteTextField
                color="info"
                label="Select one value Only"
                variant="outlined"
                sx={{ borderColor: 'white' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color="info">
                        <Info />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Stack>

            <Stack flex={1} spacing={1}>
              <Typography sx={{ color: 'white', fontSize: 16, fontWeight: 300 }}>
                Opportunity Type
              </Typography>
              <Select
                color="info"
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'white',
                  },
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  color: 'white',
                }}
                value={opportunityType}
                variant="outlined"
                displayEmpty
                onChange={(event) => setOpportunityType(event.target.value)}
              >
                <MenuItem value="">
                  Select one value Only
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              {/* <WhiteTextField
                color="info"
                label="Select one value Only"
                variant="outlined"
                sx={{ borderColor: 'white' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color="info">
                        <Info />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Stack>
          </Stack>

          {/* <Stack spacing={1}>
            <Typography sx={{ fontWeight: 300, fontSize: 16, color: 'white' }}>
              Keywords
            </Typography>
            <WhiteTextField
              color="info"
              value="Add Tags"
              variant="outlined"
              sx={{ borderColor: 'white' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Stack direction="row" spacing={1}>
                      {['Tourism', 'Hotels', 'Camping'].map((tag) => (
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            border: 1,
                            borderColor: 'secondary.main',
                            color: 'white',
                            py: '3px',
                            px: 3,
                          }}
                        >
                          <Typography
                            key={tag}
                            sx={{ color: 'white' }}
                          >
                            {tag}
                          </Typography>
                          <Info />
                        </Stack>
                      ))}
                    </Stack>
                  </InputAdornment>
                ),
              }}
            />
          </Stack> */}

          <Stack alignItems="flex-end">
            <Button
              color="secondary"
              sx={{ textTransform: 'capitalize' }}
              variant="contained"
              onClick={() => setAdvanced(false)}
            >
              Search
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
