import { Divider, Stack, Typography } from "@mui/material";
import { TextList } from "../../utilities/interface"
import { Image } from "../Image";
import { htmlParse } from "../../utilities/htmlParse";
import { getResponsiveValue } from "../../utilities/hooks";
import { isEmptyText } from "../../utilities/common";

export type TextList1Props = TextList;

export const TextList1 = ({
  content,
  image_align,
  image,
  title,
}: TextList1Props) => {
  return (
    <Stack
      direction={getResponsiveValue(
        'column',
        image_align === 'left' ? 'row' : 'row-reverse',
      )}
      spacing={3}
      sx={{
        alignItems: 'flex-end',
        alignSelf: image_align === 'left' ? 'flex-start' : 'flex-end',
        maxWidth: getResponsiveValue('100%', 891),
      }}
    >
      <Image
        src={image}
        sx={{
          height: 360,
          objectFit: 'cover',
          width: getResponsiveValue('100%', 450),
        }}
      />
      <Stack spacing={2}>
        {!isEmptyText(title) && (
          <>
            <Typography
              sx={{
                fontSize: 28,
                fontWeight: 700,
                lineHeight: '29px',
              }}
            >
              {title}
            </Typography>
            <Divider
              sx={{
                bgcolor: 'secondary.main',
                height: 1,
                width: 175,
              }}
              flexItem
            />
          </>
        )}
        <Typography
          sx={{
            fontSize: 15,
            fontWeight: 500,
            lineHeight: '21px',
            mb: 2,
          }}
        >
          {htmlParse(content)}
        </Typography>
      </Stack>
    </Stack>
  );
}
