import { IconButton, Stack, Typography, styled } from "@mui/material";
import { getResponsiveValue } from "../utilities/hooks";
import { VolumeOff, VolumeUp } from "@mui/icons-material";
import { useState } from "react";

const VideoCover = styled('video')({
  objectFit: 'cover',
  width: '100vw',
  zIndex: 0,
});

interface VideoProps {
  content: string,
  video: string,
}

export const Video = ({ content, video }: VideoProps) => {
  const [muted, setMuted] = useState(true);

  return (
    <Stack sx={{ position: 'relative' }}>
        <VideoCover
          muted={muted}
          src={video}
          sx={{
            height: getResponsiveValue(400, 550),
          }}
          autoPlay
          loop
        />
        <IconButton
          color="info"
          sx={{
            m: 4,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          onClick={() => setMuted(!muted)}
        >
          {muted ? <VolumeOff /> : <VolumeUp />}
        </IconButton>
        {content != null && (
          <Typography
            sx={{
              bottom: 0,
              color: 'white',
              fontSize: getResponsiveValue(24, 50),
              fontWeight: 700,
              lineHeight: getResponsiveValue('25px', '52px'),
              m: 4,
              position: 'absolute',
              textTransform: 'uppercase',
              whiteSpace: 'pre-line',
            }}
          >
            {content}
          </Typography>
        )}
    </Stack>
  );
}
