import {
  ArrowBack as MuiArrowBack,
  ArrowBackIos as MuiArrowBackIos,
  ArrowForward as MuiArrowForward,
  ArrowForwardIos as MuiArrowForwardIos,
  ArrowLeft as MuiArrowLeft,
  ArrowRight as MuiArrowRight,
} from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ArrowLeft = ({ ...props }: SvgIconProps) => {
  const { i18n } = useTranslation();

  return i18n.dir() === 'ltr' ? (
    <MuiArrowLeft {...props} />
  ) : (
    <MuiArrowRight {...props} />
  );
}

export const ArrowRight = ({ ...props }: SvgIconProps) => {
  const { i18n } = useTranslation();

  return i18n.dir() === 'ltr' ? (
    <MuiArrowRight {...props} />
  ) : (
    <MuiArrowLeft {...props} />
  );
}

export const ArrowForward = ({ ...props }: SvgIconProps) => {
  const { i18n } = useTranslation();

  return i18n.dir() === 'ltr' ? (
    <MuiArrowForward {...props} />
  ) : (
    <MuiArrowBack {...props} />
  );
}


export const ArrowBackIos = ({ ...props }: SvgIconProps) => {
  const { i18n } = useTranslation();

  return i18n.dir() === 'ltr' ? (
    <MuiArrowBackIos {...props} />
  ) : (
    <MuiArrowForwardIos {...props} />
  );
}

export const ArrowForwardIos = ({ ...props }: SvgIconProps) => {
  const { i18n } = useTranslation();

  return i18n.dir() === 'ltr' ? (
    <MuiArrowForwardIos {...props} />
  ) : (
    <MuiArrowBackIos {...props} />
  );
}
