import { Box, Stack, Typography } from "@mui/material";
import { getResponsiveValue, useIsMobile } from "../utilities/hooks";
import { ApiTestimonial } from "../utilities/interface";
import { Image } from "./Image";

interface TestimonialProps extends ApiTestimonial {
  direction?: 'left' | 'right' | 'center',
  size?: 'small' | 'medium'
}

export const Testimonial = ({
  description,
  direction = 'center',
  image,
  name,
  position,
  size = 'medium',
}: TestimonialProps) => {
  const isMobile = useIsMobile();

  const Img = () => (
    <Image
      src={image}
      sx={{
        height: 188,
        objectFit: 'cover',
        objectPosition: 'top center',
        width: 188,
      }}
    />
  );

  return (
    <Stack
      direction={(isMobile || direction === 'center') ? 'column' : 'row'}
      spacing={(isMobile || direction === 'center') ? 0 : 8}
      sx={{
        alignItems: (isMobile || direction === 'center') ? 'center' : 'flex-end',
        py: 4,
        textAlign: isMobile ? 'center' : direction,
      }}
    >
      {(isMobile || direction !== 'right') && <Img />}
      <Stack>
        <Typography
          sx={{
            color: 'success.main',
            fontSize: getResponsiveValue(14, size === 'small' ? 24 : 26),
            fontStyle: 'italic',
            fontWeight: size === 'small' ? 500 :  700,
            lineHeight: getResponsiveValue('19px', size === 'small' ? '30px' : '40px'),
            mb: size === 'small' ? 2 : 3,
            mt: getResponsiveValue(7, 4),
          }}
        >
          {description}
        </Typography>
        <Typography
          sx={{
            color: 'success.main',
            fontSize: 16,
            fontWeight: 700,
            mb: 1,
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            color: 'success.main',
            fontSize: getResponsiveValue(12, 16),
            fontWeight: 400,
          }}
        >
          {position}
        </Typography>
      </Stack>
      {(!isMobile && direction === 'right') && <Img />}
    </Stack>
  );
}
