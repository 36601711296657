import { Box, Divider, Stack, Typography } from "@mui/material";
import { Container } from "./Container";
import { getResponsiveValue, useIsMobile } from "../utilities/hooks";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

interface CircleProps {
  value: string;
}

const Circle = ({ value }: CircleProps) => {
  return (
    <Stack
      sx={{
        alignItems: "center",
        bgcolor: "white",
        borderRadius: 50,
        color: "#E30614",
        height: 50,
        justifyContent: "center",
        width: 50,
      }}
    >
      <Typography variant="h6">{value}</Typography>
    </Stack>
  );
};

interface CountDownProps {
  label: string;
  value: number;
}

const TimeBox = ({ label, value }: CountDownProps) => {
  return (
    <Box
      sx={{
        bgcolor: "white",
        borderRadius: 3,
        color: "#E30614",
        flex: 1,
        p: 1,
        textAlign: "center",
      }}
    >
      <Typography variant="h4">{value}</Typography>
      <Typography variant="h5">{label}</Typography>
    </Box>
  );
};

interface LottoProps {
  end_date: Date;
  id: string;
  next_rewards: string;
  result: string;
  start_date: Date;
}

export const Lotto = ({
  end_date,
  id,
  next_rewards,
  result,
  start_date,
}: LottoProps) => {
  const nextId = useMemo(() => Number(id) + 1, [id]);
  const results = useMemo(() => result.split(","), [result]);

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    show: false,
  });

  const isMobile = useIsMobile();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const diff = dayjs(end_date).diff();
      const duration = dayjs.duration(diff);

      setCountdown({
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
        show: diff > 0,
      });
    }, 1000);

    return () => clearInterval(intervalId); //This is important
  }, [end_date]);

  return (
    <Container>
      <Stack
        direction={getResponsiveValue("column", "row")}
        spacing={4}
        sx={{
          color: "white",
        }}
      >
        <Stack
          spacing={4}
          sx={{
            backgroundColor: "#E30614",
            borderRadius: 3,
            flex: 1,
            p: 4,
          }}
        >
          <Typography variant="h6">نتائج السحب</Typography>
          <Stack direction="row" spacing={2}>
            {results.map((r) => (
              <Circle value={r} />
            ))}
          </Stack>
          <Stack direction="row" spacing={4}>
            <Typography variant="h6">سحب#{id}</Typography>
            <Typography variant="h6">
              {dayjs(start_date).format("dddd MMM YYYY, D")}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          spacing={4}
          sx={{
            backgroundColor: "#E30614",
            borderRadius: 3,
            flex: 1,
            p: 4,
          }}
        >
          <Box>
            <Typography variant="h6">
              الجائزة الكبرى المتوقعه للسحب المقبل
            </Typography>
            <Typography variant="h5">{next_rewards}</Typography>
          </Box>
          <Box>
            <Stack direction="row" spacing={4}>
              <Typography variant="h6">السحب القادم #{nextId}</Typography>
              <Typography variant="h6">
                {dayjs(end_date).format("dddd MMM YYYY, D")}
              </Typography>
            </Stack>
            {countdown.show && (
              <>
                <Divider
                  sx={{ borderColor: "rgba(255,255,255,0.5)", mb: 2, mt: 2 }}
                />
                <Typography sx={{ mb: 1 }} variant="h6">
                  الوقت المتبقي للسحب المقبل
                </Typography>
                <Stack
                  direction="row"
                  divider={<Typography variant="h3">:</Typography>}
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <TimeBox label="أيام" value={countdown.days} />
                  <TimeBox label="ساعات" value={countdown.hours} />
                  <TimeBox label="دقائق" value={countdown.minutes} />
                  {!isMobile && (
                    <TimeBox label="ثواني" value={countdown.seconds} />
                  )}
                </Stack>
              </>
            )}
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};
