import { Stack } from "@mui/material";
import { Container } from "../components/Container";
import { Testimonial } from "../components/Testimonial";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../constants/queryKeys";
import { TestimonialApi } from "../utilities/api/TestimonialApi";
import { LoadingPage } from "./LoadingPage";

export const TestimonialListPage = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { data: testimonials, isLoading } = useQuery({
    queryFn: () => TestimonialApi.list(language),
    queryKey: [QueryKeys.GET_TESTIMONIAL, language],
  });

  return isLoading == null ? (
    <LoadingPage />
  ) : (
    <Container maxWidth="md" sx={{ pb: 14 }}>
      <Stack spacing={14}>
        {testimonials?.map((testimonial, index) => (
          <Testimonial
            direction={index % 2 === 0 ? "left" : "right"}
            key={index}
            size="small"
            {...testimonial}
          />
        ))}
      </Stack>
    </Container>
  );
};

export default TestimonialListPage;
