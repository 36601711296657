import { Stack, Typography } from "@mui/material";
import { TextList } from "../../utilities/interface"
import { Image } from "../Image";
import { htmlParse } from "../../utilities/htmlParse";
import { getResponsiveValue } from "../../utilities/hooks";
import { isEmptyText } from "../../utilities/common";
import { Title } from "../Title";

export type TextList3Props = TextList;

export const TextList3 = ({
  content,
  image_align,
  image,
  title,
}: TextList3Props) => {
  return (
    <Stack spacing={3}>
      <Stack
        direction={getResponsiveValue(
          'column',
          image_align === 'left' ? 'row' : 'row-reverse',
        )}
        spacing={12}
        sx={{
          alignItems: 'stretch',
          alignSelf: image_align === 'left' ? 'flex-start' : 'flex-end',
        }}
      >
        <Stack>
          <Image
            src={image}
            sx={{
              height: '100%',
              minHeight: 360,
              objectFit: 'cover',
              width: getResponsiveValue('100%', 626),
            }}
          />
        </Stack>
        <Stack sx={{ justifyContent: 'center', py: 4 }}>
          {!isEmptyText(title) && (
            <Title title={title} variant="dot" />
          )}
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 300,
              lineHeight: '23px',
            }}
          >
            {htmlParse(content)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
