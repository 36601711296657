import axios from "axios";
import { SimpleJwtLogin } from "simple-jwt-login";

export const simpleJwtLogin = new SimpleJwtLogin(
  "https://api.erba7.net",
  "/simple-jwt-login/v1"
);

const timeout = 30000;

export const cancelToken = axios.CancelToken;

export const wpClient = axios.create({
  baseURL: "https://api.erba7.net/wp-json/",
  headers: { "content-type": "application/json" },
  timeout,
});

wpClient.interceptors.response.use(
  (response) => response && response.data,
  (error) => error && error.response && error.response.data
);
