import { HorizontalRule } from "@mui/icons-material";
import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { isEmptyText } from "../utilities/common";
import { getResponsiveValue } from "../utilities/hooks";
import { htmlParse } from "../utilities/htmlParse";
import { Slide } from "../utilities/interface";
import { Carousel } from "./Carousel";
import { Container } from "./Container";
import { Grid } from "./Grid";
import { ArrowBackIos, ArrowForwardIos } from "./Icons";
import { Image } from "./Image";
import { Title } from "./Title";

interface SliderBaseProps {
  divider: boolean;
  slides: Slide[];
  subtitle: string;
  title: string;
}

const Slider1 = ({ divider, slides, subtitle, title }: SliderBaseProps) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
  });

  const nextRef = useRef<Function | undefined>();
  const prevRef = useRef<Function | undefined>();

  return (
    <Container
      ref={ref}
      sx={{
        alignItems: "center",
        backgroundColor: "#EAEAEA",
        backgroundImage: `url(${process.env.PUBLIC_URL}/background-download.png)`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        py: 14,
      }}
    >
      {!isEmptyText(title) && (
        <Title
          align="flex-start"
          divider={divider}
          subtitle={subtitle}
          title={title}
        />
      )}
      <Carousel
        activeIndicatorIconButtonProps={{
          style: {
            margin: 8,
            opacity: 1,
            transform: "scale(2)",
          },
        }}
        IndicatorIcon={<HorizontalRule fontSize="inherit" />}
        indicatorIconButtonProps={{
          style: {
            color: "#B18548",
            fontSize: 16,
            margin: 1,
            opacity: 0.5,
          },
        }}
        interval={8000}
        NavButton={(props) => {
          if (props.next) {
            nextRef.current = props.onClick;
          } else if (props.prev) {
            prevRef.current = props.onClick;
          }

          return null;
        }}
      >
        {slides.map(({ content, image, title }, index) => (
          <Stack
            key={index}
            sx={{
              alignItems: "flex-end",
              // backgroundImage: inView ? `url(${image})` : undefined,
              // backgroundRepeat: 'no-repeat',
              // backgroundSize: '60% 100%',
              height: 672,
              justifyContent: "center",
              position: "relative",
            }}
          >
            {inView && (
              <Box
                alt=""
                component="img"
                src={image}
                sx={{
                  height: "100%",
                  left: 0,
                  objectFit: "cover",
                  position: "absolute",
                  width: "60%",
                  zIndex: 0,
                }}
              />
            )}
            <Stack direction="row">
              <ButtonBase
                sx={{
                  backgroundColor: "#272727",
                  color: "white",
                  height: 80,
                  width: 80,
                }}
                onClick={() => prevRef.current?.()}
              >
                <ArrowBackIos />
              </ButtonBase>
              <ButtonBase
                sx={{
                  backgroundColor: "#B18548",
                  color: "white",
                  height: 80,
                  width: 80,
                }}
                onClick={() => nextRef.current?.()}
              >
                <ArrowForwardIos />
              </ButtonBase>
            </Stack>
            <Stack
              spacing={2}
              sx={{
                alignItems: "flex-start",
                backgroundColor: "rgba(0,0,0,0.85)",
                height: 415,
                justifyContent: "center",
                overflow: "hidden",
                p: 4,
                position: "relative",
                width: getResponsiveValue(undefined, "60%"),
              }}
            >
              <Typography
                sx={{
                  color: "info.main",
                  fontSize: 40,
                  fontWeight: 700,
                  lineHeight: "41px",
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  color: "info.main",
                  fontSize: 20,
                  fontWeight: 500,
                  lineHeight: "25px",
                  overflow: "hidden",
                }}
              >
                {htmlParse(content)}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Carousel>
    </Container>
  );
};

const Slider2 = ({ divider, slides, subtitle, title }: SliderBaseProps) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
  });
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <Container
      ref={ref}
      sx={{
        alignItems: "center",
        pb: 14,
      }}
    >
      {!isEmptyText(title) && (
        <Title divider={divider} subtitle={subtitle} title={title} />
      )}
      <Stack
        sx={{
          minHeight: 581,
          position: "relative",
        }}
      >
        <Stack
          sx={{
            backgroundColor: "#272727",
            flex: 1,
          }}
        >
          <Stack
            spacing={6}
            sx={{
              boxSizing: "border-box",
              // height: 500,
              overflow: "scroll",
              p: 6,
              width: "30%",
            }}
          >
            {slides.map((slide, index) => (
              <ButtonBase
                key={index}
                sx={{
                  color: "white",
                  justifyContent: "flex-start",
                }}
                onClick={() => setCurrentSlide(index)}
              >
                <Stack
                  direction="row"
                  spacing={4}
                  sx={{
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  {index === currentSlide && <ArrowForwardIos />}
                  <Typography
                    sx={{
                      fontSize: index === currentSlide ? 25 : 20,
                      fontWeight: index === currentSlide ? 700 : 300,
                    }}
                  >
                    {slide.title}
                  </Typography>
                </Stack>
              </ButtonBase>
            ))}
          </Stack>
        </Stack>
        {slides[currentSlide] != null && (
          <Stack
            sx={{
              alignItems: "flex-end",
              position: "absolute",
              right: 4,
              width: "70%",
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                height: 80,
                width: "50%",
              }}
            />
            <Stack
              direction="row"
              spacing={4}
              sx={{
                backgroundColor: "#E5E4E2",
                p: 6,
              }}
            >
              {inView && (
                <Box
                  alt=""
                  component="img"
                  src={slides[currentSlide].image}
                  sx={{
                    height: 474,
                    objectFit: "cover",
                    width: 278,
                  }}
                />
              )}
              <Typography
                sx={{
                  height: 474,
                  overflow: "scroll",
                }}
              >
                {htmlParse(slides[currentSlide].content)}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

const SliderDefault = ({
  divider,
  slides,
  subtitle,
  title,
}: SliderBaseProps) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
  });

  const nextRef = useRef<Function | undefined>();
  const prevRef = useRef<Function | undefined>();

  return (
    <Container
      ref={ref}
      sx={{
        alignItems: "center",
      }}
    >
      {!isEmptyText(title) && (
        <Title
          align="flex-start"
          divider={divider}
          subtitle={subtitle}
          title={title}
        />
      )}
      <Carousel
        indicatorContainerProps={{
          style: {
            bottom: 12,
            position: "absolute",
            zIndex: 1,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            color: "white",
            fontSize: 16,
            margin: 1,
            opacity: 0.5,
          },
        }}
        interval={8000}
        NavButton={(props) => {
          if (props.next) {
            nextRef.current = props.onClick;
          } else if (props.prev) {
            prevRef.current = props.onClick;
          }

          return null;
        }}
      >
        {slides.map(({ content, image, title }, index) => (
          <Stack
            key={index}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            {inView && (
              <Box
                alt=""
                component="img"
                src={image}
                sx={{
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            )}
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                bottom: 0,
                justifyContent: "space-between",
                left: 0,
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <ButtonBase
                sx={{
                  color: "secondary.main",
                  height: 80,
                  width: 80,
                }}
                onClick={() => prevRef.current?.()}
              >
                <ArrowBackIos />
              </ButtonBase>
              <ButtonBase
                sx={{
                  color: "secondary.main",
                  height: 80,
                  width: 80,
                }}
                onClick={() => nextRef.current?.()}
              >
                <ArrowForwardIos />
              </ButtonBase>
            </Stack>
          </Stack>
        ))}
      </Carousel>
    </Container>
  );
};

const Lotto = ({ divider, slides, subtitle, title }: SliderBaseProps) => {
  return slides.map((slide) => (
    <Stack
      sx={{
        borderColor: "success.main",
        borderStyle: "solid",
        borderWidth: 1,
        flex: 1,
      }}
    >
      <Typography
        sx={{
          bgcolor: "#e30613",
          color: "white",
          fontSize: 33,
          fontWeight: 400,
          p: 3,
          textAlign: "center",
        }}
      >
        {slide.title}
      </Typography>

      <Typography
        sx={{
          fontSize: 25,
          lineHeight: "50px",
          p: 3,
          whiteSpace: "pre-line",
        }}
      >
        {slide.content}
      </Typography>
    </Stack>
  ));
};

const Winners = ({ divider, slides, subtitle, title }: SliderBaseProps) => {
  return (
    <Grid desktop={6} mobile={12} spacing={2}>
      {slides.map((slide) => (
        <Stack
          sx={{
            borderColor: "success.main",
            borderStyle: "solid",
            borderWidth: 1,
            flex: 1,
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              bgcolor: "success.main",
              p: 3,
            }}
          >
            <Image
              src={slide.logo}
              sx={{
                boxSizing: "border-box",
                height: 60,
                objectFit: "contain",
                width: 60,
              }}
            />
            <Typography
              sx={{
                color: "white",
                fontSize: 33,
                fontWeight: 400,
              }}
            >
              {slide.title}
            </Typography>
          </Stack>

          <Typography
            sx={{
              fontSize: 25,
              lineHeight: "50px",
              p: 3,
              whiteSpace: "pre-line",
            }}
          >
            {slide.content}
          </Typography>
        </Stack>
      ))}
    </Grid>
  );
};

interface SliderProps extends SliderBaseProps {
  variant: "slider1" | "slider2" | "default" | "lotto" | "winners";
}

export const Slider = ({ variant, ...props }: SliderProps) => {
  switch (variant) {
    case "slider1":
      return <Slider1 {...props} />;
    case "slider2":
      return <Slider2 {...props} />;
    case "lotto":
      return <Lotto {...props} />;
    case "winners":
      return <Winners {...props} />;
    default:
      return <SliderDefault {...props} />;
  }
};
