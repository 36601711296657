import { ReactNode, useMemo } from "react";
import { isEmptyText } from "../../utilities/common";
import { TextList, TextListVariant } from "../../utilities/interface";
import { Title } from "../Title";
import { TextList1 } from "./TextList1";
import { Wrapper } from "../Wrapper";
import { Breakpoint, Stack, SxProps, Theme } from "@mui/material";
import { TextList2 } from "./TextList2";
import { TextList4 } from "./TextList4";
import { TextList3 } from "./TextList3";
import { TextList5 } from "./TextList5";

interface VariantData {
  Component: (props: any) => ReactNode,
  maxWidth?: Breakpoint,
  spacing?: number,
  sx?: SxProps<Theme>,
}

const buildVariantData = (variant: TextListVariant): VariantData => {
  switch (variant) {
    case 'list1': {
      return {
        Component: TextList1,
        maxWidth: 'lg',
        spacing: 3,
        sx: {
          backgroundColor: '#EAEAEA',
          backgroundImage: `url(${process.env.PUBLIC_URL}/background-download.png)`,
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          py: 14,
        },
      };
    }

    case 'list2': {
      return {
        Component: TextList2,
        maxWidth: 'lg',
        spacing: 6,
      };
    }

    case 'list3': {
      return {
        Component: TextList3,
        maxWidth: 'xl',
        spacing: 6,
        sx: {
          backgroundColor: '#EAEAEA',
        },
      };
    }

    case 'list4': {
      return {
        Component: TextList4,
        maxWidth: 'xl',
        spacing: 6,
      };
    }

    case 'list5': {
      return {
        Component: TextList5,
        maxWidth: 'xl',
        spacing: 6,
      };
    }

    default: {
     return {
      Component: () => null,
     };
    }
  }
}

interface DynamicListProps {
  divider: boolean,
  sub_list: TextList[],
  subtitle: string,
  title: string,
  variant: TextListVariant,
}

export const DynamicList = ({
  divider,
  sub_list,
  subtitle,
  title,
  variant,
}: DynamicListProps) => {
  const { Component, maxWidth, spacing, sx } = useMemo(
    () => buildVariantData(variant),
    [variant],
  );

  return (
    <Wrapper maxWidth={maxWidth} sx={sx}>
      {!isEmptyText(title) && (
        <Title
          divider={divider}
          subtitle={subtitle}
          title={title}
        />
      )}
      <Stack spacing={spacing}>
        {sub_list.map((props, index) => (
          <Component key={index} number={index + 1} {...props} />
        ))}
      </Stack>
    </Wrapper>
  );
}
