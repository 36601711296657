import { Opportunity } from "../utilities/interface";
import {Card} from "./Card";

interface OpportunityItemProps {
  opportunity: Opportunity;
  opportunitySlug: string,
}

export const OpportunityItem = ({ opportunity, opportunitySlug }: OpportunityItemProps) => {
  return (
    <Card
      category={opportunity.category}
      description={opportunity.description ?? ''}
      feature={opportunity.feature}
      image={opportunity.image ?? ''}
      link={`/${opportunitySlug}/${opportunity.slug}`}
      title={opportunity.name}
    />
  );
}
