import { Circle } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { Container } from "./Container";
import { Testimonial } from "./Testimonial";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../constants/queryKeys";
import { TestimonialApi } from "../utilities/api/TestimonialApi";
import { Image } from "./Image";
import { Carousel } from "./Carousel";
import { isEmptyText } from "../utilities/common";
import { Title } from "./Title";

interface TestimonialsProps {
  divider?: boolean;
  subtitle?: string;
  title?: string;
}

export const Testimonials = ({
  divider,
  subtitle,
  title,
}: TestimonialsProps) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { data: testimonials } = useQuery({
    queryFn: () => TestimonialApi.list(language),
    queryKey: [QueryKeys.GET_TESTIMONIAL, language],
  });

  return (
    <Stack>
      {!isEmptyText(title) && (
        <Title divider={divider} subtitle={subtitle} title={title} />
      )}
      <Container
        sx={{
          backgroundColor: "#212121",
          overflow: "hidden",
          position: "relative",
          py: 9,
        }}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/background-testimonials.svg`}
          sx={{
            bottom: 0,
            opacity: 0.09,
            position: "absolute",
            right: 0,
            transform: "translate(50%, 50%)",
          }}
        />
        <Stack sx={{ alignItems: "center" }}>
          <Carousel
            activeIndicatorIconButtonProps={{
              style: {
                opacity: 1,
                transform: "scale(1.4)",
              },
            }}
            IndicatorIcon={<Circle fontSize="inherit" />}
            indicatorIconButtonProps={{
              style: {
                color: "#B18548",
                fontSize: 16,
                margin: 22,
                opacity: 0.5,
              },
            }}
            interval={8000}
            NextIcon={<></>}
            PrevIcon={<></>}
            sx={{ maxWidth: 1209, width: "100%" }}
          >
            {testimonials?.map((testimonial, index) => (
              <Testimonial key={index} {...testimonial} />
            ))}
          </Carousel>
        </Stack>
      </Container>
    </Stack>
  );
};
