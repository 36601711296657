import { Stack } from "@mui/material";
import { isEmptyText } from "../../utilities/common";
import { getResponsiveValue } from "../../utilities/hooks";
import { Image } from "../Image";

export type GridLogoProps = {
  link: string;
  logo: string;
};

export const GridLogo = ({ link, logo }: GridLogoProps) => {
  return (
    <Stack
      component={isEmptyText(link) ? "div" : "a"}
      href={link}
      sx={{
        flex: getResponsiveValue(undefined, 1),
        // p: getResponsiveValue(0, 6),
      }}
    >
      <Image
        src={logo}
        sx={{
          boxSizing: "border-box",
          // maxWidth: getResponsiveValue("100%", 387),
          objectFit: "contain",
        }}
      />
    </Stack>
  );
};
