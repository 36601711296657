import { ResponsiveStyleValue } from '@mui/system';
import { Stack } from "@mui/material";
import { Figure, FigureVariant } from "../../utilities/interface";
import { Box } from "./Box";
import { Circle } from "./Circle";
import { StarBox } from "./StarBox";
import { Container } from "../Container";
import { ReactNode, useMemo } from "react";
import { getResponsiveValue, useIsMobile } from '../../utilities/hooks';
import { ArrowForward } from '../Icons';

interface VariantData {
  direction: ResponsiveStyleValue<'column' | 'row'>,
  divider?: ReactNode,
  rowGap?: number,
  spacing?: number,
}

const buildVariantData = (variant: FigureVariant, isMobile: boolean): VariantData => {
  switch (variant) {
    case 'box': {
      return {
        direction: getResponsiveValue('column', 'row'),
        spacing: 6,
      };
    }

    case 'flow': {
      return {
        direction: 'row',
        divider: isMobile ? undefined : (
          <ArrowForward
            color='secondary'
            fontSize='large'
            sx={{
              alignSelf: 'center',
            }}
          />
        ),
        rowGap: 10,
      }
    }

    default: {
      return {
        direction: 'row',
        rowGap: 10,
      };
    }
  }
}

interface FiguresProps {
  items: Figure[],
  variant: FigureVariant,
}

export const DynamicFigures = ({ items, variant }: FiguresProps) => {
  const isMobile = useIsMobile();
  const { direction, divider, rowGap, spacing } = useMemo(
    () => buildVariantData(variant, isMobile),
    [isMobile, variant],
  );

  return (
    <Container maxWidth="xl">
      <Stack
        direction={direction}
        divider={divider}
        rowGap={rowGap}
        spacing={spacing}
        sx={{ flexWrap: 'wrap' }}
      >
        {items.map((figure, index) => (
          <FigureItem
            key={index}
            variant={variant}
            {...figure}
          />
        ))}
      </Stack>
    </Container>
  );
}

interface FigureProps extends Figure {
  variant: FigureVariant,
}

export const FigureItem = ({ variant, ...props }: FigureProps) => {
  switch (variant) {
    case 'circle':
    case 'flow': {
      return (
        <Circle
          color={variant === 'flow' ? 'primary' : 'secondary'}
          {...props}
        />
      );
    }

    case 'box': {
      return (
        <Box {...props} />
      );
    }

    default: {
      return (
        <StarBox
          color={variant === 'secondary' ? 'secondary' : 'primary'}
          {...props}
        />
      );
    }
  }
}
