import { AnimatePresence, m, MotionProps, PanInfo } from "framer-motion";
import { ReactNode } from "react";
import useDimensions from "react-cool-dimensions";
import { StyledItem } from "./Styled";

export interface CarouselItemProps {
  animation: "fade" | "slide";
  next?: Function;
  prev?: Function;
  state: {
    active: number;
    prevActive: number;
    next: boolean;
  };
  swipe?: boolean;
  index: number;
  maxIndex: number;
  duration: number;
  child: ReactNode;
  height?: number | string;
  setHeight: Function;
}

export const CarouselItem = ({
  animation,
  next,
  prev,
  swipe,
  state,
  index,
  maxIndex,
  duration,
  child,
  height,
  setHeight,
}: CarouselItemProps) => {
  const slide = animation === "slide";
  const fade = animation === "fade";

  const dragProps: MotionProps = {
    drag: "x",
    dragConstraints: { left: 0, right: 0 },
    dragElastic: 0,
    layout: true,
    onDragEnd: (
      event: MouseEvent | TouchEvent | PointerEvent,
      info: PanInfo
    ): void => {
      if (!swipe) return;

      if (info.offset.x > 0) prev && prev();
      else if (info.offset.x < 0) next && next();

      event.stopPropagation();
    },
  };

  const { observe } = useDimensions({
    onResize: ({ height }) => {
      if (index !== state.active) return;
      if (height > 0) {
        setHeight(height);
      }
    },
  });

  const variants = {
    center: {
      opacity: 1,
      x: 0,
      zIndex: 1,
      // position: 'relative'
    },
    leftOut: {
      display: "none",
      opacity: fade ? 0 : undefined,
      x: slide ? "-100%" : undefined,
      zIndex: 0,
      // position: 'relative'
    },
    leftwardExit: {
      opacity: fade ? 0 : undefined,
      x: slide ? "-100%" : undefined,
      zIndex: 0,
      // position: 'relative'
    },
    rightOut: {
      display: "none",
      opacity: fade ? 0 : undefined,
      x: slide ? "100%" : undefined,
      zIndex: 0,
      // position: 'relative'
    },
    rightwardExit: {
      opacity: fade ? 0 : undefined,
      x: slide ? "100%" : undefined,
      zIndex: 0,
      // position: 'relative'
    },
  };

  // Handle animation directions and opacity given based on active, prevActive and this item's index
  const { active, next: isNext, prevActive } = state;
  let animate = "center";
  if (index === active) animate = "center";
  else if (index === prevActive) {
    animate = isNext ? "leftwardExit" : "rightwardExit";
    if (active === maxIndex && index === 0) animate = "rightwardExit";
    if (active === 0 && index === maxIndex) animate = "leftwardExit";
  } else {
    animate = index < active ? "leftOut" : "rightOut";
    if (active === maxIndex && index === 0) animate = "rightOut";
    if (active === 0 && index === maxIndex) animate = "leftOut";
  }

  duration = duration / 1000;

  return (
    <StyledItem>
      <AnimatePresence custom={isNext}>
        <m.div {...(swipe && dragProps)} style={{ height: "100%" }}>
          <m.div
            animate={animate}
            custom={isNext}
            style={{ height: "100%", position: "relative" }}
            transition={{
              opacity: { duration: duration },
              x: { delay: 0, duration: duration, type: "tween" },
            }}
            variants={variants}
          >
            <div ref={observe} style={{ height: height }}>
              {child}
            </div>
          </m.div>
        </m.div>
      </AnimatePresence>
    </StyledItem>
  );
};
