import { CircularProgress, Stack } from "@mui/material";

export const LoadingPage = () => (
  <Stack
    sx={{
      alignItems: 'center',
      height: '100vh',
      justifyContent: 'center',
      width: '100vw',
    }}
  >
    <CircularProgress />
  </Stack>
);
