import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { OpportunityItem } from "../components/OpportunityItem";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../constants/queryKeys";
import { OpportunityApi } from "../utilities/api/OpportunityApi";
// import { Categories } from "../utilities/interface";
import { LoadingPage } from "./LoadingPage";
import { Grid } from "../components/Grid";

interface OpportunityListPageProps {
  map_slug: string;
  opportunity_slug: string;
}

export const OpportunityListPage = ({
  opportunity_slug,
}: OpportunityListPageProps) => {
  const [searchParams] = useSearchParams();
  const { id } = useParams<{ id: string }>();

  const {
    i18n: { language },
  } = useTranslation();

  const { data, isLoading } = useQuery({
    queryFn: () => OpportunityApi.list(language),
    queryKey: [QueryKeys.GET_OPPORTUNITY, language],
  });

  const opportunities = useMemo(() => {
    const search = searchParams.get("q");

    if (search == null && id == null) {
      return data?.filter(({ hidden }) => !hidden);
    }

    return data?.filter((opportunity) => {
      const matchCategory =
        id == null ||
        id.replace(" ", "-").toLowerCase() ===
          opportunity.category.replace(" ", "-").toLowerCase();
      const matchSearch =
        search
          ?.split(/\s+/)
          .every((s) => new RegExp(s, "gi").test(opportunity.name)) ?? true;
      return matchCategory && matchSearch;
    });
  }, [data, id, searchParams]);

  // const categories = useMemo(() => (
  //   data?.reduce((acc, opportunity) => {
  //     if (acc[opportunity.category] == null) {
  //       acc[opportunity.category] = [];
  //     }

  //     acc[opportunity.category].push(opportunity);

  //     return acc;
  //   }, {} as Categories)
  // ), [data]);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <Box sx={{ pb: 14, px: 2 }}>
      <Grid columnSpacing={2} desktop={4} mobile={12} rowSpacing={14}>
        {opportunities?.map((opportunity) => (
          <OpportunityItem
            key={opportunity.id}
            opportunity={opportunity}
            opportunitySlug={opportunity_slug}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default OpportunityListPage;
