import React from 'react';
import { LazyMotion as Motion } from 'framer-motion';

const loadFeatures = () => import('./features').then((res) => res.default);

export interface LazyMotionProps {
  children: React.ReactNode;
  strict?: boolean;
}

export const LazyMotion = ({ children, strict }: LazyMotionProps) => {
  return (
    <Motion features={loadFeatures} strict={strict}>
      {children}
    </Motion>
  );
}
