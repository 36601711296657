import { Divider, Stack, Typography } from "@mui/material";
import { TextList } from "../../utilities/interface"
import { Image } from "../Image";
import { htmlParse } from "../../utilities/htmlParse";
import { getResponsiveValue, getResponsiveValues, useIsMobile } from "../../utilities/hooks";
import { isEmptyText } from "../../utilities/common";

export interface TextList2Props extends TextList {
  number: string,
};

export const TextList2 = ({
  content,
  image_align,
  image,
  number,
  title,
}: TextList2Props) => {
  const isMobile = useIsMobile();

  return (
    <Stack direction={getResponsiveValue('column', 'row')} spacing={8}>
      <Stack
        direction={getResponsiveValue('row', 'column')}
        spacing={6}
        sx={{
          alignItems: 'center',
        }}
      >
        {isMobile && (
          <Stack sx={{ flex: 1 }}>
            <Divider
              orientation={isMobile ? 'horizontal' : 'vertical'}
              sx={{
                bgcolor: '#B88E46',
              }}
            />
          </Stack>
        )}
        <Typography
          sx={{
            color: '#B88E46',
            fontSize: 49,
            fontWeight: 700,
            lineHeight: '48px',
            textShadow: '0 0 1px #B18548, 0 0 1px #B18548',
          }}
        >
          {number.toString().padStart(2, '0')}
        </Typography>
        <Stack sx={{ flex: 1 }}>
          <Divider
            orientation={isMobile ? 'horizontal' : 'vertical'}
            sx={{
              bgcolor: '#B88E46',
            }}
          />
        </Stack>
      </Stack>
      <Stack spacing={6}>
        <Image
          src={image}
          sx={{
            height: 360,
            objectFit: 'cover',
            width: getResponsiveValue('100%', 450),
          }}
        />
        {!isEmptyText(title) && (
          <Typography
            sx={{
              fontSize: 33,
              fontWeight: 700,
              lineHeight: '32px',
            }}
          >
            {title}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: 21,
            fontWeight: 500,
            lineHeight: '21px',
          }}
        >
          {htmlParse(content)}
        </Typography>
      </Stack>
    </Stack>
  );
}
