import GoogleMapReact from 'google-map-react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import '../styles/Map.css';
import { Marker } from './Marker';
import { ButtonBase, Stack } from '@mui/material';
import { getResponsiveValue } from '../utilities/hooks';
import { Opportunity } from '../utilities/interface';
import { Image } from './Image';

const GOOGLE_MAPS_API_KEY = 'AIzaSyAYYct_1SHOSuMn47wiQ58YqoFeshpxTi0';
const OFFSET_X = 0;
const OFFSET_Y = 484 / 2;

const MAP_OPTIONS = {
  center: {
    lat: 31.29537624546205,
    lng: 36.909255518062224,
  },
  zoom: 7,
};

interface Coords {
  lat: number,
  lng: number,
}

function haversineDistance(mk1: Coords, mk2: Coords) {
  var R = 3958.8; // Radius of the Earth in miles
  var rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
  var rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

  var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));

  const meters = d * 1609.344;
  return meters;
}

// function offsetCenter(
//   map,
//   latlng,
//   offsetx: number,
//   offsety: number,
// ) {
// 	// latlng is the apparent centre-point
// 	// offsetx is the distance you want that point to move to the right, in pixels
// 	// offsety is the distance you want that point to move upwards, in pixels
// 	// offset can be negative
// 	// offsetx and offsety are both optional
	
// 	var scale = Math.pow(2, map.getZoom());
// 	// var nw = new google.maps.LatLng(
// 	//     map.getBounds().getNorthEast().lat(),
// 	//     map.getBounds().getSouthWest().lng()
// 	// );

// 	var worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
// 	var pixelOffset = new google.maps.Point((offsetx / scale) ?? 0, (offsety / scale) ?? 0)
	
// 	var worldCoordinateNewCenter = new google.maps.Point(
// 	    worldCoordinateCenter.x - pixelOffset.x,
// 	    worldCoordinateCenter.y + pixelOffset.y
// 	);
	
// 	var newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
	
// 	map.setCenter(newCenter);
// }

interface MapProps {
  onClick(opportunity: Opportunity | null): void;
  opportunities: Opportunity[],
  opportunity: Opportunity | null,
  opportunitySlug: string,
}

export const Map = ({
  onClick,
  opportunities,
  opportunity,
  opportunitySlug,
}: MapProps) => {
  const [mapOptions, setMapOptions] = React.useState({
    center: MAP_OPTIONS.center,
    radius: 1000,
  });

  const [mapTypeId, setMapTypeId] = useState<string | undefined>();

  const satelliteMode = useMemo(() => mapTypeId === 'hybrid' || mapTypeId === 'satellite', [mapTypeId]);

  const color = satelliteMode ? '#FFFFFF' : '#B98E45';

  const mapRef = useRef<google.maps.Map | null>(null);
  const mapsRef = useRef<typeof google.maps | null>(null);
  const handleGoogleApiLoaded = useCallback((maps: any) => {
    mapRef.current = maps.map;
    mapsRef.current = maps.maps;
  }, []);

  const center = useMemo<Coords>(() => {
    if (opportunity == null) {
      return mapOptions.center;
    }

    const latLng = {
      lat: opportunity.latitude,
      lng: opportunity.longitude,
    };

    if (mapRef.current == null || mapsRef.current == null) {
      return latLng;
    }

    const point1 = mapRef.current.getProjection()?.fromLatLngToPoint(latLng);
    const point2 = new mapsRef.current.Point(
      OFFSET_X / Math.pow(2, mapRef.current.getZoom() ?? 0),
      OFFSET_Y / Math.pow(2, mapRef.current.getZoom() ?? 0)
    );

    if (point1 == null || point2 == null) {
      return latLng;
    }

    const newLatLng = mapRef.current.getProjection()?.fromPointToLatLng(new mapsRef.current.Point(
      point1?.x + point2?.x,
      point1?.y - point2?.y
    ));

    return {
      lat: newLatLng?.lat() ?? opportunity.latitude,
      lng: newLatLng?.lng() ?? opportunity.longitude,
    };
  }, [mapOptions.center, opportunity]);

  return (
    <Stack
      sx={{
        height: getResponsiveValue('calc(100vh - 108px)', 'calc(100vh - 154px)'),
        width: '100%',
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        center={center}
        defaultCenter={MAP_OPTIONS.center}
        defaultZoom={MAP_OPTIONS.zoom}
        options={map => ({
          fullscreenControl: false,

          mapTypeControl: false,
          mapTypeId: mapTypeId ?? map.MapTypeId.ROADMAP,
          rotateControl: false,
          scaleControl: false,
          scrollwheel: false,
          streetViewControl: false,
          styles: [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f5f5f5'
                }
              ]
            },
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#616161'
                }
              ]
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#f5f5f5'
                }
              ]
            },
            {
              elementType: 'geometry.fill',
              featureType: 'administrative.country',
              stylers: [
                {
                  color: '#616161'
                }
              ]
            },
            {
              elementType: 'geometry.stroke',
              featureType: 'administrative.country',
              stylers: [
                {
                  color: '#787878'
                }
              ]
            },
            {
              elementType: 'labels',
              featureType: 'administrative.land_parcel',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              featureType: 'administrative.land_parcel',
              stylers: [
                {
                  color: '#b18447'
                }
              ]
            },
            {
              elementType: 'geometry.stroke',
              featureType: 'administrative.neighborhood',
              stylers: [
                {
                  color: '#787878'
                }
              ]
            },
            {
              elementType: 'geometry.stroke',
              featureType: 'administrative.province',
              stylers: [
                {
                  color: '#787878'
                }
              ]
            },
            {
              elementType: 'geometry.fill',
              featureType: 'landscape.man_made',
              stylers: [
                {
                  color: '#e3e3e3'
                }
              ]
            },
            {
              elementType: 'geometry.fill',
              featureType: 'landscape.natural',
              stylers: [
                {
                  visibility: 'on'
                }
              ]
            },
            {
              elementType: 'geometry.fill',
              featureType: 'landscape.natural.landcover',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              elementType: 'geometry.fill',
              featureType: 'landscape.natural.terrain',
              stylers: [
                {
                  color: '#c9e5b8'
                }
              ]
            },
            {
              elementType: 'geometry',
              featureType: 'poi',
              stylers: [
                {
                  color: '#eeeeee'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              featureType: 'poi',
              stylers: [
                {
                  color: '#757575'
                }
              ]
            },
            {
              elementType: 'geometry',
              featureType: 'poi.park',
              stylers: [
                {
                  color: '#c9e5b8'
                }
              ]
            },
            {
              elementType: 'geometry.fill',
              featureType: 'poi.park',
              stylers: [
                {
                  color: '#c9e5b8'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              featureType: 'poi.park',
              stylers: [
                {
                  color: '#9e9e9e'
                }
              ]
            },
            {
              elementType: 'geometry',
              featureType: 'road',
              stylers: [
                {
                  color: '#ffffff'
                }
              ]
            },
            {
              elementType: 'labels',
              featureType: 'road.arterial',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              featureType: 'road.arterial',
              stylers: [
                {
                  color: '#757575'
                }
              ]
            },
            {
              elementType: 'geometry.stroke',
              featureType: 'road.highway',
              stylers: [
                {
                  color: '#b08446'
                },
                {
                  'weight':0.5
                }
              ]
            },
            {
              elementType: 'labels',
              featureType: 'road.highway',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              featureType: 'road.highway',
              stylers: [
                {
                  color: '#616161'
                }
              ]
            },
            {
              elementType: 'geometry.stroke',
              featureType: 'road.highway.controlled_access',
              stylers: [
                {
                  color: '#b08446'
                }
              ]
            },
            {
              elementType: 'geometry.fill',
              featureType: 'road.local',
              stylers: [
                {
                  color: '#e8e8e8'
                }
              ]
            },
            {
              elementType: 'geometry.stroke',
              featureType: 'road.local',
              stylers: [
                {
                  color: '#ededed'
                }
              ]
            },
            {
              elementType: 'labels',
              featureType: 'road.local',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              featureType: 'road.local',
              stylers: [
                {
                  color: '#9e9e9e'
                }
              ]
            },
            {
              elementType: 'geometry',
              featureType: 'transit.line',
              stylers: [
                {
                  color: '#e5e5e5'
                }
              ]
            },
            {
              elementType: 'geometry',
              featureType: 'transit.station',
              stylers: [
                {
                  color: '#e3e3e3'
                }
              ]
            },
            {
              elementType: 'geometry.fill',
              featureType: 'transit.station.airport',
              stylers: [
                {
                  color: '#ffffff'
                }
              ]
            },
            {
              elementType: 'geometry',
              featureType: 'water',
              stylers: [
                {
                  color: '#acd4fb'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              featureType: 'water',
              stylers: [
                {
                  color: '#9e9e9e'
                }
              ]
            }
          ],
        
          zoomControl: true,
          zoomControlOptions: {
            position: map.ControlPosition.TOP_RIGHT,
          },
        })}
        yesIWantToUseGoogleMapApiInternals
        onChange={({ center, bounds }) => {
          setMapOptions({
            center,
            radius: haversineDistance(center, bounds.ne)
          });
        }}
        onClick={() => onClick(null)}
        onGoogleApiLoaded={handleGoogleApiLoaded}
        onMapTypeIdChange={(newMapTypeId: string) => setMapTypeId(newMapTypeId)}
      >
        {opportunity == null ? opportunities?.map((_opportunity) => (
          <Marker
            color={color}
            key={_opportunity.id}
            lat={_opportunity.latitude}
            lng={_opportunity.longitude}
            opportunity={_opportunity}
            opportunitySlug={opportunitySlug}
            onClick={onClick}
          />
        )) : (
          <Marker
            color={color}
            key={opportunity.id}
            lat={opportunity.latitude}
            lng={opportunity.longitude}
            opportunity={opportunity}
            opportunitySlug={opportunitySlug}
            showName
            onClick={() => onClick(null)}
          />
        )}
      </GoogleMapReact>
      <ButtonBase
        sx={{
          borderRadius: 4,
          bottom: 34,
          position: 'absolute',
          right: 22,
          zIndex: 2,
        }}
        onClick={() => setMapTypeId(satelliteMode ? 'roadmap' : 'satellite')}
      >
        <Image src={`${process.env.PUBLIC_URL}/map-${satelliteMode ? 'roadmap' :  'satellite'}.png`} />
      </ButtonBase>
    </Stack>
  );
}
