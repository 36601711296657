import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../constants/queryKeys";
import { CustomPageApi } from "./api/CustomPageApi";
import { useTranslation } from "react-i18next";

export const getValuesFromTerms = (
  terms: any[] | undefined,
  taxonomy: string
) => {
  return (
    terms?.flat().reduce((acc, term) => {
      if (term.taxonomy === taxonomy) {
        acc.push(term.name);
      }

      return acc;
    }, [] as string[]) ?? []
  );
};

export const usePageId = (slug?: string | Record<string, string>) => {
  const {
    i18n: { language },
  } = useTranslation();
  const currentSlug = typeof slug === "string" ? slug : slug?.[language];

  const { data: pageId, isFetching } = useQuery({
    enabled: currentSlug != null,
    queryFn: () => CustomPageApi.getId(currentSlug!, language),
    queryKey: [QueryKeys.GET_CUSTOM_PAGE, currentSlug, language],
  });

  return {
    isFetching,
    pageId,
  };
};
