import { Breakpoint, Stack, SxProps, Theme } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { getResponsiveValue } from "../../utilities/hooks";
import { arrayChunk, isEmptyText } from "../../utilities/common";
import { Grid, GridVariant } from "../../utilities/interface";
import { Title, TitleVariant } from "../Title";
import { Grid2 } from "./Grid2";
// import { Grid1 } from "./Grid1";
import { Wrapper } from "../Wrapper";
import { Grid3 } from "./Grid3";
import { GridLogo } from "./GridLogo";
import { GridThird } from "./GridThird";

interface VariantData {
  Component: (props: any) => ReactNode;
  maxWidth?: Breakpoint;
  perChunk: number;
  spacing?: number;
  sx?: SxProps<Theme>;
  titleVariant?: TitleVariant;
}

const buildVariantData = (variant: GridVariant): VariantData => {
  switch (variant) {
    case "hover": {
      return {
        Component: Grid2,
        perChunk: 4,
        spacing: 4,
        sx: {
          px: 4,
        },
      };
    }

    case "hover2": {
      return {
        Component: Grid2,
        maxWidth: "lg",
        perChunk: 2,
        spacing: 4,
      };
    }

    case "hover3": {
      return {
        Component: Grid2,
        maxWidth: "lg",
        perChunk: 3,
        spacing: 4,
      };
    }

    case "simple": {
      return {
        Component: Grid3,
        maxWidth: "xl",
        perChunk: 3,
        spacing: 6,
        titleVariant: "dot",
      };
    }

    case "logo": {
      return {
        Component: GridLogo,
        maxWidth: "lg",
        perChunk: 6,
        spacing: 4,
      };
    }

    case "third": {
      return {
        Component: GridThird,
        maxWidth: "lg",
        perChunk: 3,
        spacing: 4,
      };
    }

    default: {
      return {
        Component: GridThird,
        maxWidth: "lg",
        perChunk: 2,
        spacing: 4,
      };
    }
  }
};

interface DynamicGridProps {
  divider: boolean;
  items: Grid[];
  subtitle: string;
  title: string;
  variant: GridVariant;
}

export const DynamicGrid = ({
  divider,
  items,
  subtitle,
  title,
  variant,
}: DynamicGridProps) => {
  const { Component, maxWidth, perChunk, spacing, sx, titleVariant } = useMemo(
    () => buildVariantData(variant),
    [variant]
  );

  const group = useMemo(
    () => (items == null ? null : arrayChunk(items, perChunk)),
    [items, perChunk]
  );

  return group == null ? null : (
    <Wrapper maxWidth={maxWidth}>
      {!isEmptyText(title) && (
        <Title
          divider={divider}
          subtitle={subtitle}
          title={title}
          variant={titleVariant}
        />
      )}
      <Stack spacing={spacing}>
        {group.map((sectors, columnIndex) => (
          <Stack
            direction={getResponsiveValue("column", "row")}
            key={columnIndex}
            spacing={spacing}
            sx={sx}
          >
            {sectors.map((props, rowIndex) => (
              <Component
                key={`${columnIndex}-${rowIndex}`}
                {...props}
                direction={columnIndex % 2 === 0 ? "right" : "left"}
                number={columnIndex * perChunk + rowIndex + 1}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </Wrapper>
  );
};
