import { Stack } from "@mui/material";
import { Container } from "../components/Container";
import { SuccessStory } from "../components/SuccessStory";
import { Title } from "../components/Title";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../constants/queryKeys";
import { SuccessStoryApi } from "../utilities/api/SuccessStoryApi";
import { LoadingPage } from "./LoadingPage";

export const SuccessStoryListPage = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const { data: successStories, isLoading } = useQuery({
    queryFn: () => SuccessStoryApi.list(language),
    queryKey: [QueryKeys.GET_SUCCESS_STORY, language],
  });

  return isLoading == null ? (
    <LoadingPage />
  ) : (
    <Container maxWidth="lg" sx={{ pb: 14 }}>
      <Stack spacing={14}>
        {successStories?.map(({ name, items }, idx) => (
          <Fragment key={idx}>
            {name != null && <Title title={name} divider />}
            {items.map((successStory, index) => (
              <SuccessStory
                direction={index % 2 === 0 ? "left" : "right"}
                key={index}
                {...successStory}
              />
            ))}
          </Fragment>
        ))}
      </Stack>
    </Container>
  );
};

export default SuccessStoryListPage;
