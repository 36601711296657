import { Divider, Stack, Typography } from "@mui/material";
import { getResponsiveValue } from "../utilities/hooks";
import { isEmptyText } from "../utilities/common";
import { useMemo } from "react";
import { Square } from "@mui/icons-material";

export type TitleVariant = "default" | "dot";
export type TitleAlign = "center" | "flex-start" | "flex-end";

interface TitleProps {
  align?: TitleAlign;
  color?: string;
  divider?: boolean;
  subtitle?: string;
  title: string;
  variant?: TitleVariant;
}

export const Title = ({
  color,
  align = "center",
  divider,
  subtitle,
  title,
  variant = "default",
}: TitleProps) => {
  const textAlign = useMemo(() => {
    if (align === "flex-start") {
      return "left";
    } else if (align === "flex-end") {
      return "right";
    }

    return "center";
  }, [align]);

  return variant === "default" ? (
    <Stack
      sx={{
        alignItems: align,
        pb: divider ? 0 : 4,
      }}
    >
      <Typography
        sx={{
          color: color ?? "primary.main",
          fontSize: getResponsiveValue(28, 40),
          fontWeight: 400,
          lineHeight: getResponsiveValue("28px", "60px"),
          textAlign,
        }}
      >
        {title}
      </Typography>
      {!isEmptyText(subtitle) && (
        <Typography
          sx={{
            color: color ?? "secondary.main",
            fontSize: getResponsiveValue(17, 21),
            fontWeight: 300,
            lineHeight: getResponsiveValue("22px", "31px"),
            textAlign,
            whiteSpace: "pre-line",
          }}
        >
          {subtitle}
        </Typography>
      )}
      {divider && (
        <Divider
          sx={{
            alignSelf: align,
            bgcolor: color ?? "success.main",
            height: 1,
            mb: 6,
            mt: 2,
            width: "100%",
          }}
          flexItem
        />
      )}
    </Stack>
  ) : (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        alignItems: "flex-start",
        // justifyContent: align,
        pb: divider ? 0 : 4,
      }}
    >
      <Square sx={{ color: "#B88E46" }} />
      <Typography
        sx={{
          fontSize: 25,
          fontWeight: 700,
          lineHeight: "24px",
        }}
      >
        {title}
      </Typography>
    </Stack>
  );
};
