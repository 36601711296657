import { Stack, Typography } from "@mui/material";
import { TextList } from "../../utilities/interface"
import { Image } from "../Image";
import { htmlParse } from "../../utilities/htmlParse";
import { getResponsiveValue } from "../../utilities/hooks";
import { isEmptyText } from "../../utilities/common";
import { Title } from "../Title";

export type TextList5Props = TextList;

export const TextList5 = ({
  content,
  image_align,
  image,
  title,
}: TextList5Props) => {
  return (
    <Stack spacing={3}>
      {(image_align === 'right' && !isEmptyText(title)) && (
        <Title title={title} variant="dot" />
      )}
      <Stack
        direction={getResponsiveValue(
          'column',
          image_align === 'left' ? 'row' : 'row-reverse',
        )}
        spacing={12}
        sx={{
          alignItems: 'stretch',
          alignSelf: image_align === 'left' ? 'flex-start' : 'flex-end',
        }}
      >
        <Stack>
          <Image
            src={image}
            sx={{
              height: 360,
              minHeight: '100%',
              objectFit: 'cover',
              width: getResponsiveValue('100%', 626),
            }}
          />
        </Stack>
        <Stack sx={{ justifyContent: 'center' }}>
          {(image_align === 'left' && !isEmptyText(title)) && (
            <Title title={title} variant="dot" />
          )}
          <Typography
            sx={{
              borderColor: '#B88E46',
              borderStyle: 'solid',
              borderWidth: 0,
              // eslint-disable-next-line sort-keys-fix/sort-keys-fix
              borderLeftWidth: image_align === 'left' ? 10 : 0,
              borderRightWidth: image_align === 'left' ? 0 : 10,
              fontSize: 18,
              fontWeight: 300,
              lineHeight: '23px',
              pl: image_align === 'left' ? 5 : 0,
              pr: image_align === 'left' ? 0 : 5,
            }}
          >
            {htmlParse(content)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
