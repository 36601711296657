import { Stack, Typography } from "@mui/material";
import { getResponsiveValue } from "../utilities/hooks";
import { isEmptyText } from "../utilities/common";

interface CoverProps {
  content: string,
  image: string,
}

export const Cover = ({ content, image }: CoverProps) => {
  return (
    <Stack
      sx={{
        background: `url(${image}) center bottom`,
        backgroundSize: 'cover',
        boxSizing: 'border-box',
        height: getResponsiveValue(150, 550),
        justifyContent: 'flex-end',
      }}
    >
      <Stack
        sx={{
          background: 'linear-gradient(to top, rgb(39 39 39 / 55%), transparent)',
          height: 292,
          justifyContent: 'center',
        }}
      >
        {!isEmptyText(content) && (
          <Typography
            sx={{
              color: 'white',
              fontSize: getResponsiveValue(24, 50),
              fontWeight: 600,
              lineHeight: getResponsiveValue('25px', '52px'),
              textAlign: 'center',
              textTransform: 'uppercase',
              whiteSpace: 'pre-line',
            }}
          >
            {content}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
