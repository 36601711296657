import { isEmptyText } from "../utilities/common";
import { Zone } from "../utilities/interface";
import { Container } from "./Container";
import { DevelopmentZoneItem } from "./DevelopmentZoneItem";
import { Grid } from "./Grid";
import { Title } from "./Title";

interface ZoneListProps {
  divider: boolean,
  items: Zone[],
  subtitle: string,
  title: string,
}

export const ZoneList = ({
  divider,
  items,
  subtitle,
  title,
}: ZoneListProps) => {
  return (
    <>
      {!isEmptyText(title) && (
        <Title
          divider={divider}
          subtitle={subtitle}
          title={title}
        />
      )}
      <Container maxWidth="xl" sx={{ py: 14 }}>
        <Grid columnSpacing={16} desktop={6} mobile={12} rowSpacing={4}>
          {items?.map(({ title, zones }, index) => (
            <DevelopmentZoneItem
              key={index}
              title={title}
              zones={zones}
            />
          ))}
        </Grid>
      </Container>
    </>
  );
}
