import { Place as PlaceIcon } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import React from 'react';
import '../styles/Marker.css';
import { Opportunity } from '../utilities/interface';
import { useTranslation } from 'react-i18next';

export interface Props {
  color: string,
  lat: number,
  lng: number,
  onClick(opportunity: Opportunity): void,
  opportunity: Opportunity,
  opportunitySlug: string,
  showName?: boolean,
}

export function Marker({
  color,
  onClick,
  opportunity,
  opportunitySlug,
  showName,
}: Props) {
  const { t } = useTranslation();
  const [visibleInfo, setVisibleInfo] = React.useState(false);

  return (
    <div
      className='marker'
      onClick={() => onClick(opportunity)}
      onMouseOut={showName ? undefined : () => setVisibleInfo(false)}
      onMouseOver={showName ? undefined : () => setVisibleInfo(true)}
    >
      <PlaceIcon
        fontSize='large'
        sx={{ color }}
      />
      {(showName || visibleInfo) && (
        <Card
          sx={{
            backgroundColor: 'white',
            maxWidth: '80vw',
            position: 'relative',
            transform: 'translate(-45%, -115%)',
            width: 484,
          }}
        >
          <CardContent>
            {opportunity.image != null && (
              <Stack
                sx={{ position: 'relative' }}
              >
                <CardMedia
                  alt=""
                  component="img"
                  height="257"
                  image={opportunity.image}
                  sx={{
                    borderRadius: 6,
                    mb: 2,
                  }}
                />
                <Typography
                  sx={{
                    backgroundColor: 'secondary.main',
                    color: 'secondary.contrastText',
                    fontSize: 16,
                    fontWeight: 500,
                    position: 'absolute',
                    px: 2,
                    py: 1,
                  }}
                >
                  {opportunity.category}
                </Typography>
              </Stack>
            )}
            <Typography sx={{ fontSize: 26, fontWeight: 700 }}>
              {opportunity.name}
            </Typography>
            {opportunity.description != null && (
              <Typography
                color="text.secondary"
                sx={{
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  display: '-webkit-box',
                  fontSize: 13,
                  fontWeight: 400,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {opportunity.description}
              </Typography>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              color="success"
              href={`/${opportunitySlug}/${opportunity.slug}`}
              size="large"
              sx={{
                px: 8,
                textTransform: 'capitalize',
              }}
              variant="contained"
            >
              {t('LEARN_MORE')}
            </Button>
          </CardActions>
        </Card>
      )}
    </div>
  );
}
