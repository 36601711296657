import { Box as MuiBox, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { getResponsiveValue } from "../../utilities/hooks";
import { isEmptyText } from "../../utilities/common";
import { useInView } from "react-intersection-observer";

interface BoxProps {
  color?: 'primary' | 'secondary',
  image: string,
  subtitle: string,
  sup?: string,
  title: string,
}

export const Box = ({
  color = 'primary',
  image,
  sup,
  subtitle,
  title,
}: BoxProps) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
  });

  return (
    <Stack
      ref={ref}
      sx={{
        alignItems: 'flex-start',
        backgroundImage: inView ? `url(${image})` : undefined,
        backgroundSize: 'cover',
        borderBottomWidth: 1,
        borderColor: '#B88E46',
        borderLeftWidth: 10,
        borderRightWidth: 1,
        borderStyle: 'solid',
        borderTopWidth: 1,
        boxSizing: 'border-box',
        flex: 1,
        justifyContent: 'center',
        minHeight: 240,
        p: 5,
      }}
    >
      {!isEmptyText(title) && (
        <Typography
          sx={{
            color: color === 'primary' ? 'secondary.main' : undefined,
            fontSize: getResponsiveValue(39, 60),
            fontWeight: '700',
            letterSpacing: -3,
          }}
        >
          {title}
          {!isEmptyText(sup) && (
            <MuiBox
              component="sup"
              sx={{
                verticalAlign: 'top',
              }}
            >
              {sup}
            </MuiBox>
          )}
        </Typography>
      )}
      {!isEmptyText(subtitle) && (
        <Typography
          sx={{
            color: color === 'secondary' ? 'secondary.main' : undefined,
            fontSize: getResponsiveValue(11, 18),
            fontWeight: '300',
            lineHeight: getResponsiveValue('14px', '23px'),
            whiteSpace: 'pre-line',
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
}
