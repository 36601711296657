import { Stack } from "@mui/material";
import { isEmptyText } from "../../utilities/common";
import { getResponsiveValue } from "../../utilities/hooks";
import { Image } from "../Image";

export type GridThirdProps = {
  image: string;
  link: string;
  logo: string;
};

export const GridThird = ({ image, link, logo }: GridThirdProps) => {
  return (
    <Stack
      component={isEmptyText(link) ? "div" : "a"}
      href={link}
      sx={{
        flex: getResponsiveValue(undefined, 1),
        position: "relative",
        // p: getResponsiveValue(0, 6),
      }}
    >
      <Image
        src={logo}
        sx={{
          boxSizing: "border-box",
          height: 100,
          left: 24,
          objectFit: "contain",
          position: "absolute",
          top: 24,
          width: 100,
        }}
      />
      <Image
        src={image}
        sx={{
          boxSizing: "border-box",
          // maxWidth: getResponsiveValue("100%", 387),
          objectFit: "contain",
        }}
      />
    </Stack>
  );
};
