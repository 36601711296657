import {Button, Stack, Typography} from "@mui/material";
import {Image} from "./Image";
import {isEmptyText} from "../utilities/common";
import {useTranslation} from "react-i18next";

interface CardProps {
  category: string,
  description: string,
  feature: string,
  image:string,
  link: string,
  title: string,
}

export const Card = ({
  category,
  description,
  feature,
  image,
  link,
  title,
}: CardProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        height: 634,
        p: 2,
        position: 'relative',
      }}
    >
      {!isEmptyText(image) && (
        <Stack
          sx={{ position: 'relative' }}
        >
          <Image
            src={image}
            sx={{
              height: 342,
              objectFit: 'cover',
              width: '100%',
            }}
          />
          {!isEmptyText(category) && (
            <Typography
              sx={{
                backgroundColor: 'text.secondary',
                color: 'secondary.contrastText',
                fontSize: 16,
                fontWeight: 500,
                position: 'absolute',
                px: 2,
                py: 1,
              }}
            >
              {category}
            </Typography>
          )}
          {!isEmptyText(feature) && (
            <Typography
              sx={{
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText',
                fontSize: 16,
                fontWeight: 500,
                position: 'absolute',
                px: 2,
                py: 1,
                right: 0,
              }}
            >
              {feature}
            </Typography>
          )}
        </Stack>
      )}
      <Typography sx={{ fontSize: 26, fontWeight: 700, mt: 4 }}>
        {title}
      </Typography>
      {description != null && (
        <Typography
          color="text.secondary"
          sx={{
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 4,
            display: '-webkit-box',
            fontSize: 18,
            fontWeight: 300,
            mt: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {description}
        </Typography>
      )}
      <Button
        color="success"
        href={link}
        size="large"
        sx={{
          borderRadius: 0,
          bottom: 0,
          height: 48,
          left: '50%',
          position: 'absolute',
          textTransform: 'capitalize',
          transform: 'translate(-50%, 50%)',
          width: 260,
        }}
        variant="contained"
      >
        {t('LEARN_MORE')}
      </Button>
    </Stack>
  );
}
