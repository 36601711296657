import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

export interface RightToLeftProps {
  children: ReactNode;
}

export const RightToLeft = ({ children }: RightToLeftProps) => {
  const { i18n } = useTranslation();

  return i18n.dir() === 'ltr' ? children : (
    <CacheProvider value={cacheRtl}>{children}</CacheProvider>
  );
}
