import {
  Breakpoint,
  GridSize,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { OptionsApi } from "./api/OptionsApi";
import { QueryKeys } from "../constants/queryKeys";
import { v4 } from "uuid";
// @ts-ignore
import { evaluate } from "angel-eval";
import { isEmptyText } from "./common";

export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}

export const useMode = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useLocalStorage<"dark" | "light">(
    "mode",
    prefersDarkMode ? "dark" : "light"
  );

  return { mode, setMode };
};

export const BREAKPOINT_DESKTOP: Breakpoint = "md";
export const BREAKPOINT_MOBILE: Breakpoint = "xs";
type CssVariableType = string | number | null;
type CssVariables = { [key: string]: CssVariableType };

export const useIsMobile = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(BREAKPOINT_DESKTOP), {
    noSsr: true,
  });

  return matches;
};

export const getResponsiveValues = (
  mobile: CssVariables,
  desktop: CssVariables,
  sx?: SxProps<Theme>
): SxProps<Theme> => {
  const styles: any = sx ?? {};
  Object.keys(mobile).forEach((key) => {
    styles[key] = {
      [BREAKPOINT_DESKTOP]: desktop[key],
      [BREAKPOINT_MOBILE]: mobile[key],
    };
  });

  Object.keys(desktop).forEach((key) => {
    if (!styles[key]) {
      styles[key] = {
        [BREAKPOINT_DESKTOP]: desktop[key],
        [BREAKPOINT_MOBILE]: undefined,
      };
    }
  });

  return styles;
};

export const getResponsiveValue = <T, P>(
  mobile: T,
  desktop: P
): ResponsiveStyleValue<T | P> => {
  return {
    [BREAKPOINT_DESKTOP]: desktop,
    [BREAKPOINT_MOBILE]: mobile,
  };
};

export const getGridResponsiveSize = (mobile: GridSize, desktop: GridSize) => {
  return {
    [BREAKPOINT_DESKTOP]: desktop,
    [BREAKPOINT_MOBILE]: mobile,
  };
};

export const useSettings = () => {
  const { data } = useQuery({
    gcTime: Infinity,
    queryFn: OptionsApi.getSettings,
    queryKey: [QueryKeys.GET_SETTINGS],
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data != null) {
      TagManager.initialize({
        gtmId: data.global.google_analytics_id,
      });
    }
  }, [data]);

  return data ?? null;
};

export const useUuid = () => {
  const ref = useRef(v4());

  return ref.current;
};

export const useCondition = (
  condition: string,
  values: Record<string, any>
) => {
  return isEmptyText(condition) ? true : evaluate(condition, values);
};
