import { Circle, Close } from "@mui/icons-material";
import { Box, ButtonBase, IconButton, Modal, Stack, Typography } from "@mui/material";
import { DevelopmentZone, SXStyles, Zone } from "../utilities/interface";
import { useState } from "react";
import { ArrowForward } from "./Icons";
import { getResponsiveValue } from "../utilities/hooks";
import { useTranslation } from "react-i18next";
import { Carousel } from "./Carousel";
import { Image } from "./Image";
import { isEmptyText } from "../utilities/common";
import { htmlParse } from "../utilities/htmlParse";

// const isZone = (developmentZone: DevelopmentZone | Zone): developmentZone is Zone => {
//   return 'zones' in developmentZone;
// }

const styles: SXStyles = {
  box: {
    bgcolor: 'background.paper',
    height: getResponsiveValue('100%', 'auto'),
    left: '50%',
    maxHeight: '90%',
    overflow: 'auto',
    p: 2,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: getResponsiveValue('100%', '80%'),
  },
  content: {
    backgroundColor: 'rgba(255, 255, 255, 0.17)',
    borderColor: 'secondary.main',
    borderStyle: 'solid',
    borderWidth: 0.5,
    boxSizing: 'border-box',
    color: 'white',
    flex: 1,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '32px',
    minHeight: 278,
    minWidth: getResponsiveValue('100%', 409),
    p: 6,
    whiteSpace: 'pre-line',
  },
  title: {
    color: 'secondary.main',
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '32px',
  },
} as const;

interface ItemProps {
  developmentZone: DevelopmentZone,
}

const Item = ({ developmentZone }: ItemProps) => {
  const { t } = useTranslation('zone');
  const [open, setOpen] = useState(false);

  return (
    <>
      <Stack
        direction={getResponsiveValue('column', 'row')}
        spacing={6}
        sx={{ overflow: 'scroll', p: 5 }}
      >
        <Stack spacing={4}>
          <Typography sx={styles.title}>
            {t('MASTER_DEVELOPER')}
          </Typography>
          <Typography sx={styles.content}>
            {htmlParse(developmentZone.developer)}
          </Typography>
        </Stack>
        {!isEmptyText(developmentZone.land_use) && (
          <Stack spacing={4}>
            <Typography sx={styles.title}>
              {t('LAND_USE')}
            </Typography>
            <Typography sx={styles.content}>
              {htmlParse(developmentZone.land_use)}
            </Typography>
          </Stack>
        )}
        {!isEmptyText(developmentZone.image) && (
          <Stack spacing={4}>
            <Typography sx={styles.title}>
              {t('MASTER_PLAN')}
            </Typography>
            <ButtonBase
              sx={{
                ...styles.content,
                backgroundImage: `url(${developmentZone.image})`,
                backgroundSize: 'cover',
              }}
              onClick={() => setOpen(true)}
            />
          </Stack>
        )}
      </Stack>
      <Modal
        componentsProps={{
          backdrop: {
            style: {
              backgroundColor: '#272727',
              opacity: 0.19,
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={styles.box}>
          <IconButton
            color="secondary"
            sx={{ position: 'fixed' }}
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
          <Image
            src={developmentZone.image}
            sx={{ width: '100%' }}
          />
        </Box>
      </Modal>
    </>
  );
}

type DevelopmentZoneItemProps = Zone;

export const DevelopmentZoneItem = ({
  title,
  zones,
}: DevelopmentZoneItemProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonBase
        sx={{
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: '0px 1px 10px rgba(0,0,0,0.2)',
          height: '100%',
          justifyContent: 'space-between',
          p: 2,
          textAlign: 'left',
          width: '100%',
        }}
        onClick={() => setOpen(true)}
      >
        <Typography sx={{ fontSize: 21, fontWeight: 300, lineHeight: '28px' }}>
          {title}
        </Typography>
        <ArrowForward color="secondary" />
      </ButtonBase>
      <Modal
        componentsProps={{
          backdrop: {
            style: {
              backgroundColor: '#272727',
              opacity: 0.19,
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={styles.box}>
          <IconButton
            color="secondary"
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
          {zones.length > 1 ? (
            <Carousel
              activeIndicatorIconButtonProps={{
                style: {
                  opacity: 1,
                  transform: 'scale(1.4)'
                },
              }}
              IndicatorIcon={<Circle fontSize='inherit' />}
              indicatorIconButtonProps={{
                style: {
                  color: '#B18548',
                  fontSize: 16,
                  margin: 22,
                  opacity: 0.5,
                },
              }}
              interval={8000}
              NextIcon={<></>}
              PrevIcon={<></>}
              sx={{ width: 'auto' }}
            >
              {zones.map((dz, index) => (
                <Item developmentZone={dz} key={index} />
              ))}
            </Carousel>
          ) : (
            <Item developmentZone={zones[0]} />
          )}
        </Box>
      </Modal>
    </>
  );
}