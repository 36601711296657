import { Breakpoint, Stack, SxProps, Theme } from "@mui/material";
import { Container } from "./Container";
import { ReactNode } from "react";

interface WrapperProps {
  children: ReactNode,
  maxWidth?: Breakpoint,
  sx?: SxProps<Theme>,
}

export const Wrapper = ({ children, maxWidth, sx }: WrapperProps) => {
  return maxWidth == null ? (
    <Stack sx={sx}>
      {children}
    </Stack>
  ) : (
    <Container maxWidth={maxWidth} sx={sx}>
      {children}
    </Container>
  );
}
